import React from 'react';

import HeaderFooterWrapper from 'components/common/HeaderFooterWrapper';

const HowLoanProcessWorksPage = (): JSX.Element => {
  /*
  TODO:
  import {
  createTheme,
  MuiThemeProvider,
  Step,
  StepConnector,
  StepIcon,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";

  const steps = [
    "Get Pre-Approved",
    "Select Preferred Loan Option",
    "Submit Full Application",
    "Receive Loan",
    "Welcome Home",
  ];

  const muiTheme = createTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          color: "#2563EB",
          "&$active": {
            color: "#2563EB",
          },
          "&$completed": {
            color: "#2563EB",
          },
        },
      },
    },
  });

  const CustomizedConnector = withStyles({
    // this attribute will reduce the gap. change 7px to whatever value that fit your design
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 70px)",
      right: "calc(50% + 70px)",
    },

  })(StepConnector);

  <MuiThemeProvider theme={muiTheme}>
  <Stepper
    activeStep={5}
    style={{
      paddingLeft: 0,
      paddingRight: 0,
    }}
    alternativeLabel={false}
    orientation={"vertical"}
    connector={<CustomizedConnector />}
  >
    {steps.map((label, index) => (
      <Step key={label} style={{ padding: 0 }}>
        <StepLabel icon={<StepIcon icon={index + 1} />}>
          {label}
        </StepLabel>
      </Step>
    ))}
  </Stepper>
</MuiThemeProvider>
*/

  return (
    <HeaderFooterWrapper>
      <div className="py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                How Our Loan Process Works
              </h1>
            </div>
          </div>
          <div className="">
            <div className="md:bg-white md:p-6">
              <div className="">
                <div className="prose prose-lg text-gray-900 lg:max-w-none">
                  <p>
                    We've made our process so simple and transparent that we
                    don't think you'll be left with any questions to ask.
                    Working with us is a breeze, and applying for one of our
                    loans requires little effort on your part--because it's all
                    done online. Also, our friendly Personal Loan Experts are
                    here to answer any questions you may have about the process,
                    your loan, or anything else you can think of!
                  </p>
                  <ol className="pl-8">
                    <li>
                      <span className="font-semibold">Get Pre-Approved</span>
                      <p>
                        Getting your personal loan options is easy. We'll ask
                        you some basic questions that you probably know off the
                        top of your head.
                      </p>
                    </li>
                    <li>
                      <span className="font-semibold">
                        Select Preferred Loan Option
                      </span>
                      <p>
                        We'll show you the loan options you qualify for, and
                        you'll be able to choose the amount, term, and rate that
                        best fit your needs.
                      </p>
                    </li>
                    <li>
                      <span className="font-semibold">
                        Submit Full Application
                      </span>
                      <p>
                        This is where you'll complete your application by
                        providing some additional information, including your
                        bank account information, and allow us to securely
                        validate all of the information provided. We attempt to
                        validate everything real-time, but in some cases, we may
                        need to request additional documentation (for example,
                        driver's license, pay stubs, bank statements and/or tax
                        forms) to support the information you filled out on your
                        application.
                      </p>
                    </li>
                    <li>
                      <span className="font-semibold">Receive Loan</span>
                      <p>
                        Once you agree to the final terms (recapping everything
                        you've already seen), we'll start the process to approve
                        and fund your loan.
                      </p>
                    </li>
                    <li>
                      <span className="font-semibold">Welcome Home</span>
                      <p>
                        That's it! We will work with your community or retailer
                        to close the purchase and make sure you get home.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderFooterWrapper>
  );
};

export default HowLoanProcessWorksPage;
