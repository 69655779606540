import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import './index.css';
import {
  REACT_APP_DEPLOYMENT_ENVIRONMENT,
  REACT_APP_NAME,
  REACT_APP_VERSION,
} from 'components/common/constants';

import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://82e297e662454f449ca07b182fd0306b@o1362190.ingest.sentry.io/4504436885946368',
  release: REACT_APP_NAME + '@' + REACT_APP_VERSION,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: REACT_APP_DEPLOYMENT_ENVIRONMENT,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
