export const enum AppRoutes {
  Home = '/',
  HowItWorks = '/how-it-works',
  About = '/about',
  Contact = '/contact',
  ComingSoon = '/coming-soon',
  GLBAPrivacyPolicy = '/documents/privacy-policy',
  TermsOfUse = '/terms-of-use',
  Documents = '/documents',
  ESignConsentAgreement = '/documents/esign-consent-agreement',
  InformationSharingDisclosure = '/documents/information-sharing-disclosure',
}
