import React from 'react';

import HeaderFooterWrapper from 'components/common/HeaderFooterWrapper';
import AlwaysExpanding from 'components/landing/AlwaysExpanding';
import HomeStartsHere from 'components/landing/HomeStartsHere';
import HowItWorks from 'components/landing/HowItWorks';
import OurRates from 'components/landing/OurRates';
import WhyMullbry from 'components/landing/WhyMullbry';

const LandingPage = (): JSX.Element => {
  //console.log("value of isLoggedIn:");
  //console.log(isLoggedIn);

  //TODO: fix, gross with div soup
  return (
    <>
      <HeaderFooterWrapper>
        <HomeStartsHere />
        <WhyMullbry />
        <OurRates />
        <HowItWorks />
        <AlwaysExpanding />
      </HeaderFooterWrapper>
    </>
  );
};

export default LandingPage;
