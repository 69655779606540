export const API_BASE_URL_BACKEND = process.env.REACT_APP_API_BASE_URL_BACKEND;
export const FIREBASE_CONFIG_API_KEY =
  process.env.REACT_APP_FIREBASE_CONFIG_API_KEY;
export const FIREBASE_CONFIG_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN;
export const FIREBASE_CONFIG_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID;
export const FIREBASE_CONFIG_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET;
export const FIREBASE_CONFIG_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID;
export const FIREBASE_CONFIG_APP_ID =
  process.env.REACT_APP_FIREBASE_CONFIG_APP_ID;
export const FIREBASE_CONFIG_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID;
export const REACT_APP_DEPLOYMENT_ENVIRONMENT =
  process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT;
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
export const REACT_APP_NAME = process.env.REACT_APP_NAME;
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const MullbryGlbaPrivacyPolicyPdf = 'Mullbry-GLBA-Privacy-Policy.pdf';
export const MullbryESignConsentAgreementPdf =
  'Mullbry-ESign-Consent-Agreement.pdf';
export const MullbryInformationSharingDisclosurePdf =
  'Mullbry-Information-Sharing-Disclosure.pdf';
export const HelpPhoneNumber = '585-450-3749';
export const HelpEmail = 'help@mullbry.com';
export const HelpAddress = '104 East Ave, Rochester, NY 14604';
