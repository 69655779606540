import React from 'react';

import {
  createTheme,
  MuiThemeProvider,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';

import ApprovedGuySvg from 'components/common/svg/ApprovedGuySvg';
import CheckListFormSvg from 'components/common/svg/CheckListFormSvg';
import DoorSvg from 'components/common/svg/DoorSvg';
import OptionsSvg from 'components/common/svg/OptionsSvg';
import PercentGuySvg from 'components/common/svg/PercentGuySvg';

const HowItWorks = (): JSX.Element => {
  const steps = [
    'Get Pre-Approved',
    'Select Loan',
    'Application Review',
    'Receive Loan',
    'Welcome Home',
  ];

  const muiTheme = createTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          color: '#2563EB',
          '&$active': {
            color: '#2563EB',
          },
          '&$completed': {
            color: '#2563EB',
          },
        },
      },
    },
  });

  return (
    <div className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            How It Works
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            You found your dream house. Now what?
          </p>
          <span className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Don't worry - It's a simple process and our team can help you along
            the way.
          </span>
        </div>

        <div className="mt-10">
          <div className="flex justify-center md:space-x-40 space-x-16">
            <PercentGuySvg />
            <CheckListFormSvg />
            <DoorSvg />
          </div>
          <div>
            <MuiThemeProvider theme={muiTheme}>
              <Stepper
                activeStep={5}
                style={{
                  backgroundColor: '#F3F4F6',
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label} style={{ padding: 0 }}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MuiThemeProvider>
          </div>
          <div className="flex justify-center md:space-x-48 space-x-32 mt-8 mb-12">
            <OptionsSvg />
            <ApprovedGuySvg />
          </div>
          <div className="flex justify-center">
            <a
              className="capitalize cursor-pointer bg-blue-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              href="/how-it-works"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
