const MountainHomeSvg = (): JSX.Element => {
  return (
    <svg
      width="170"
      height="115"
      viewBox="0 0 220 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M137.303 25.6492C143.38 25.6492 148.306 20.7245 148.306 14.6496C148.306 8.57476 143.38 3.65011 137.303 3.65011C131.227 3.65011 126.301 8.57476 126.301 14.6496C126.301 20.7245 131.227 25.6492 137.303 25.6492Z"
        fill="#005CFF"
      />
      <path
        d="M58.4372 6.0486C58.4335 6.36378 58.3527 6.67327 58.2019 6.95008C58.0511 7.2269 57.8349 7.46264 57.572 7.63675L57.4997 7.68195H24.9059L25.037 7.28266C25.0596 7.21184 27.4456 0.289663 38.0082 1.62618C39.0045 1.28565 48.7261 -1.88763 54.5455 1.67742C57.1274 3.25955 58.4372 4.73016 58.4372 6.0486Z"
        fill="#F2F2F2"
      />
      <path
        d="M110.485 62.3633C113.766 62.4337 116.376 64.8104 116.376 64.8104C116.376 64.8104 113.667 67.073 110.386 67.0026C107.105 66.9323 104.496 64.5556 104.496 64.5556C104.496 64.5556 107.205 62.293 110.485 62.3633Z"
        fill="#005CFF"
      />
      <path
        d="M93.9923 51.1683C96.9562 52.5759 100.355 51.6214 100.355 51.6214C100.355 51.6214 98.9481 48.3845 95.9842 46.9769C93.0203 45.5692 89.6217 46.5238 89.6217 46.5238C89.6217 46.5238 91.0284 49.7606 93.9923 51.1683Z"
        fill="#005CFF"
      />
      <path
        d="M94.2936 49.7689C97.5613 49.4704 100.422 51.5389 100.422 51.5389C100.422 51.5389 97.9838 54.0915 94.7161 54.39C91.4483 54.6886 88.5881 52.62 88.5881 52.62C88.5881 52.62 91.0259 50.0675 94.2936 49.7689Z"
        fill="#3F3D56"
      />
      <path
        d="M106.272 50.3348C105.959 53.6002 108.015 56.4688 108.015 56.4688C108.015 56.4688 110.579 54.0431 110.893 50.7777C111.206 47.5123 109.149 44.6437 109.149 44.6437C109.149 44.6437 106.585 47.0694 106.272 50.3348Z"
        fill="#3F3D56"
      />
      <path
        d="M121.661 70.3617C119.947 73.159 120.537 76.6386 120.537 76.6386C120.537 76.6386 123.906 75.5828 125.62 72.7855C127.334 69.9881 126.744 66.5086 126.744 66.5086C126.744 66.5086 123.375 67.5643 121.661 70.3617Z"
        fill="#3F3D56"
      />
      <path
        d="M116.689 100.776L116.939 100.607C116.058 99.3301 115.578 97.82 115.558 96.2689C115.565 94.0001 116.68 91.9015 117.758 89.8721C117.958 89.4954 118.157 89.1199 118.349 88.7447C119.154 87.2145 119.796 85.6038 120.264 83.9391C121.936 77.7267 120.29 70.3971 115.86 64.3326C112.346 59.5216 107.107 55.3147 99.845 51.4713L99.704 51.7377C106.925 55.5591 112.13 59.7371 115.616 64.5103C119.993 70.5018 121.621 77.7357 119.973 83.8609C119.51 85.5051 118.876 87.0961 118.081 88.6075C117.889 88.9813 117.691 89.3554 117.491 89.7308C116.396 91.7926 115.264 93.9246 115.256 96.2681C115.276 97.8795 115.775 99.4487 116.689 100.776Z"
        fill="#3F3D56"
      />
      <path
        d="M85.5303 51.8672C87.1119 51.8672 88.394 50.5854 88.394 49.0043C88.394 47.4231 87.1119 46.1414 85.5303 46.1414C83.9487 46.1414 82.6665 47.4231 82.6665 49.0043C82.6665 50.5854 83.9487 51.8672 85.5303 51.8672Z"
        fill="#3F3D56"
      />
      <path
        d="M157.656 104.158H0L2.6603 100.812L41.6929 51.7194L43.4896 49.4592L59.6169 29.1749L78.8279 5.01214L103.551 36.1077L157.656 104.158Z"
        fill="#3F3D56"
      />
      <path
        d="M104.752 36.6728C103.737 37.2752 102.677 37.7991 101.581 38.2397C95.9964 40.4586 89.7856 40.5198 84.1584 38.4113C77.5041 35.8861 71.9737 30.4114 64.9284 29.4038C62.7729 29.0955 60.5951 29.2306 58.4156 29.3609L78.6773 3.87683L104.752 36.6728Z"
        fill="#F2F2F2"
      />
      <path
        d="M112.246 3.94634C112.782 5.60623 112.426 7.41176 111.989 9.1005C111.551 10.7892 111.031 12.5167 111.254 14.2468C111.737 17.9853 115.399 20.383 118.798 22.0151C122.197 23.6471 126.021 25.3408 127.519 28.8002C128.348 30.7137 128.308 32.8722 128.631 34.9322C129.331 39.2807 131.644 43.2059 135.11 45.9252C132.224 45.8568 129.639 44.1025 127.596 42.0635C125.553 40.0246 123.858 37.6389 121.66 35.7684C118.697 33.2473 114.997 31.804 111.519 30.0608C108.041 28.3177 104.575 26.077 102.775 22.6288C100.934 19.1039 101.138 14.7937 102.426 11.032C103.383 8.23823 105.143 3.90295 107.409 1.90858C109.193 0.338698 111.571 1.85942 112.246 3.94634Z"
        fill="#F2F2F2"
      />
      <path
        d="M202.136 82.1511H98.2881V104.15H202.136V82.1511Z"
        fill="#F2F2F2"
      />
      <path
        d="M94.9724 84.562L106.277 60.9053H196.559L205 84.562H94.9724Z"
        fill="#3F3D56"
      />
      <path
        d="M165.058 62.4121H123.158V76.2745H165.058V62.4121Z"
        fill="#F2F2F2"
      />
      <path
        d="M125.87 51.1115L119.239 64.9741H168.977L165.058 51.1115H125.87Z"
        fill="#3F3D56"
      />
      <path
        d="M159.934 86.5209H126.85V104.15H159.934V86.5209Z"
        fill="#CCCCCC"
      />
      <path
        d="M119.992 87.1237H102.358V104.15H119.992V87.1237Z"
        fill="#CCCCCC"
      />
      <path d="M174.78 88.1783H165.661V104.15H174.78V88.1783Z" fill="#3F3D56" />
      <path
        d="M189.099 89.0819H184.652V93.2255H189.099V89.0819Z"
        fill="#005CFF"
      />
      <path
        d="M195.052 89.0819H190.606V93.2255H195.052V89.0819Z"
        fill="#005CFF"
      />
      <path
        d="M189.099 94.7326H184.652V99.0269H189.099V94.7326Z"
        fill="#005CFF"
      />
      <path
        d="M195.052 94.7326H190.606V99.0269H195.052V94.7326Z"
        fill="#005CFF"
      />
      <path
        d="M130.481 66.2053H127.001V69.4482H130.481V66.2053Z"
        fill="#3F3D56"
      />
      <path d="M135.14 66.2053H131.66V69.4482H135.14V66.2053Z" fill="#3F3D56" />
      <path
        d="M130.481 70.6275H127.001V73.9883H130.481V70.6275Z"
        fill="#3F3D56"
      />
      <path d="M135.14 70.6275H131.66V73.9883H135.14V70.6275Z" fill="#3F3D56" />
      <path
        d="M156.556 66.2053H153.076V69.4482H156.556V66.2053Z"
        fill="#3F3D56"
      />
      <path
        d="M161.215 66.2053H157.735V69.4482H161.215V66.2053Z"
        fill="#3F3D56"
      />
      <path
        d="M156.556 70.6275H153.076V73.9883H156.556V70.6275Z"
        fill="#3F3D56"
      />
      <path
        d="M161.215 70.6275H157.735V73.9883H161.215V70.6275Z"
        fill="#3F3D56"
      />
      <path
        d="M143.594 66.2053H140.114V69.4482H143.594V66.2053Z"
        fill="#3F3D56"
      />
      <path
        d="M148.253 66.2053H144.773V69.4482H148.253V66.2053Z"
        fill="#3F3D56"
      />
      <path
        d="M143.594 70.6275H140.114V73.9883H143.594V70.6275Z"
        fill="#3F3D56"
      />
      <path
        d="M148.253 70.6275H144.773V73.9883H148.253V70.6275Z"
        fill="#3F3D56"
      />
      <path
        d="M169.887 89.7962H167.696V91.838H169.887V89.7962Z"
        fill="#F2F2F2"
      />
      <path
        d="M172.821 89.7962H170.63V91.838H172.821V89.7962Z"
        fill="#F2F2F2"
      />
      <path
        d="M169.887 92.5806H167.696V94.6966H169.887V92.5806Z"
        fill="#F2F2F2"
      />
      <path
        d="M172.821 92.5806H170.63V94.6966H172.821V92.5806Z"
        fill="#F2F2F2"
      />
      <path
        d="M173.574 97.897C173.907 97.897 174.177 97.6272 174.177 97.2943C174.177 96.9615 173.907 96.6916 173.574 96.6916C173.241 96.6916 172.971 96.9615 172.971 97.2943C172.971 97.6272 173.241 97.897 173.574 97.897Z"
        fill="#F2F2F2"
      />
      <path
        d="M137.228 47.4976H132.254V48.6924H133.249V52.1685H136.233V48.6924H137.228V47.4976Z"
        fill="#F2F2F2"
      />
      <path
        d="M127.041 99.0253C126.184 102.192 123.249 104.153 123.249 104.153C123.249 104.153 121.703 100.98 122.56 97.8135C123.417 94.647 126.352 92.6858 126.352 92.6858C126.352 92.6858 127.898 95.8587 127.041 99.0253Z"
        fill="#005CFF"
      />
      <path
        d="M119.245 99.0253C120.102 102.192 123.037 104.153 123.037 104.153C123.037 104.153 124.583 100.98 123.726 97.8135C122.869 94.647 119.934 92.6858 119.934 92.6858C119.934 92.6858 118.389 95.8587 119.245 99.0253Z"
        fill="#005CFF"
      />
      <path
        d="M120.505 98.3448C122.856 100.633 123.143 104.15 123.143 104.15C123.143 104.15 119.618 103.958 117.267 101.67C114.916 99.3816 114.628 95.8642 114.628 95.8642C114.628 95.8642 118.153 96.0566 120.505 98.3448Z"
        fill="#3F3D56"
      />
      <path
        d="M125.781 98.3448C123.43 100.633 123.143 104.15 123.143 104.15C123.143 104.15 126.668 103.958 129.019 101.67C131.37 99.3816 131.658 95.8642 131.658 95.8642C131.658 95.8642 128.133 96.0566 125.781 98.3448Z"
        fill="#3F3D56"
      />
      <path
        d="M160.213 18.2902C160.21 18.6054 160.129 18.9149 159.978 19.1917C159.827 19.4685 159.611 19.7043 159.348 19.8784L159.276 19.9236H126.682L126.813 19.5243C126.836 19.4534 129.222 12.5313 139.784 13.8678C140.781 13.5273 150.502 10.354 156.322 13.919C158.904 15.5012 160.213 16.9718 160.213 18.2902Z"
        fill="#F2F2F2"
      />
      <path
        d="M59.8814 56.5843C59.8777 56.8995 59.7969 57.209 59.6461 57.4858C59.4953 57.7626 59.2791 57.9984 59.0163 58.1725L58.9439 58.2177H26.3501L26.4813 57.8184C26.5039 57.7475 28.8898 50.8254 39.4524 52.1619C40.4487 51.8214 50.1703 48.6481 55.9897 52.2131C58.5716 53.7953 59.8814 55.2659 59.8814 56.5843Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export default MountainHomeSvg;
