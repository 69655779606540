import React from 'react';

import HeaderFooterWrapper from 'components/common/HeaderFooterWrapper';

const AboutPage = (): JSX.Element => {
  return (
    <HeaderFooterWrapper>
      <div className="py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Our Story & Mission
              </h1>
            </div>
          </div>
          <div className="">
            <div className="md:bg-white md:p-6">
              <div className="">
                <div className="prose prose-lg text-gray-900 lg:max-w-none">
                  <p>
                    Having experienced first-hand the difficulty and expenses in
                    obtaining a loan to purchase a manufactured home, we knew
                    there was a better way. A way where customers could easily
                    get help to become homeowners in a fair and transparent
                    manner. We've combined cutting edge loan technology and a
                    passion for supporting our borrowers to create Mullbry.
                  </p>
                  <p>
                    We are an online lender dedicated to providing quality,
                    affordable home loans to Americans everywhere. Our mission
                    is help borrowers afford their dream homes whether they're
                    purchasing their first home, moving into a new home, or
                    refinancing. We believe everyone deserves a place they can
                    call home. We are here to change the game - to put access to
                    affordable homes at your fingertips using technology and a
                    team of experts to help get you there.
                  </p>
                  <p>
                    We use innovative software and cutting edge technology to
                    efficiently service clients in a 24/7 virtual environment.
                    Our loan underwriting process is consistently recognized for
                    strong quality and discipline. Our support staff delivers
                    premium service to borrowers, partners and agents. In
                    addition to its ability to approve loans for nearly any
                    credit scenario - including highly complex credit -
                    Mullbry's pricing model is specifically designed to be
                    transparent, fair and predictable with no hidden fees or
                    costs. We want consumers receive the best value in the
                    manufactured housing industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderFooterWrapper>
  );
};

export default AboutPage;
