const SingleStorySvg = (): JSX.Element => {
  return (
    <svg
      width="172"
      height="104"
      viewBox="0 0 172 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.8679 34.487H46.1371V102.837H47.8679V34.487Z"
        fill="#3F3D56"
      />
      <path
        d="M63.8426 43.1975C63.9528 67.0978 47.1327 86.5508 47.1327 86.5508C47.1327 86.5508 30.134 67.2537 30.0238 43.3533C29.9136 19.453 46.7337 0 46.7337 0C46.7337 0 63.7324 19.2971 63.8426 43.1975Z"
        fill="#005CFF"
      />
      <path
        d="M168.306 74.5402H34.3471V102.918H168.306V74.5402Z"
        fill="#F2F2F2"
      />
      <path
        d="M30.0698 77.6497L44.6517 47.1339H161.112L172 77.6497H30.0698Z"
        fill="#3F3D56"
      />
      <path
        d="M113.867 80.1767H71.1907V102.918H113.867V80.1767Z"
        fill="#CCCCCC"
      />
      <path
        d="M62.3443 80.9541H39.5966V102.918H62.3443V80.9541Z"
        fill="#CCCCCC"
      />
      <path
        d="M133.018 82.3147H121.255V102.918H133.018V82.3147Z"
        fill="#3F3D56"
      />
      <path
        d="M171.872 102.849H5.98685V103.402H171.872V102.849Z"
        fill="#3F3D56"
      />
      <path
        d="M26.6243 57.7388H25.4822V102.843H26.6243V57.7388Z"
        fill="#3F3D56"
      />
      <path
        d="M37.1657 63.4865C37.2384 79.2582 26.139 92.0951 26.139 92.0951C26.139 92.0951 14.9216 79.361 14.8489 63.5894C14.7762 47.8177 25.8757 34.9809 25.8757 34.9809C25.8757 34.9809 37.093 47.7149 37.1657 63.4865Z"
        fill="#005CFF"
      />
      <path
        opacity="0.1"
        d="M37.1657 63.4865C37.2384 79.2582 26.139 92.0951 26.139 92.0951C26.139 92.0951 14.9216 79.361 14.8489 63.5894C14.7762 47.8177 25.8757 34.9809 25.8757 34.9809C25.8757 34.9809 37.093 47.7149 37.1657 63.4865Z"
        fill="black"
      />
      <path
        d="M11.7761 57.7388H10.6338V102.843H11.7761V57.7388Z"
        fill="#3F3D56"
      />
      <path
        d="M22.3171 63.4865C22.3898 79.2582 11.2904 92.0951 11.2904 92.0951C11.2904 92.0951 0.0730588 79.361 0.00035561 63.5894C-0.0723476 47.8177 11.0271 34.9809 11.0271 34.9809C11.0271 34.9809 22.2444 47.7149 22.3171 63.4865Z"
        fill="#005CFF"
      />
      <path
        d="M151.488 83.4809H145.753V88.826H151.488V83.4809Z"
        fill="#3F3D56"
      />
      <path
        d="M159.168 83.4809H153.432V88.826H159.168V83.4809Z"
        fill="#3F3D56"
      />
      <path
        d="M151.488 90.7697H145.753V96.3092H151.488V90.7697Z"
        fill="#3F3D56"
      />
      <path
        d="M159.168 90.7697H153.432V96.3092H159.168V90.7697Z"
        fill="#3F3D56"
      />
      <path
        d="M75.8738 53.9709H71.3851V58.154H75.8738V53.9709Z"
        fill="#3F3D56"
      />
      <path
        d="M81.8839 53.9709H77.3952V58.154H81.8839V53.9709Z"
        fill="#3F3D56"
      />
      <path
        d="M75.8738 59.675H71.3851V64.0102H75.8738V59.675Z"
        fill="#3F3D56"
      />
      <path
        d="M81.8839 59.675H77.3952V64.0102H81.8839V59.675Z"
        fill="#3F3D56"
      />
      <path
        d="M109.509 53.9709H105.021V58.154H109.509V53.9709Z"
        fill="#3F3D56"
      />
      <path
        d="M115.519 53.9709H111.03V58.154H115.519V53.9709Z"
        fill="#3F3D56"
      />
      <path
        d="M109.509 59.675H105.021V64.0102H109.509V59.675Z"
        fill="#3F3D56"
      />
      <path d="M115.519 59.675H111.03V64.0102H115.519V59.675Z" fill="#3F3D56" />
      <path
        d="M92.7887 53.9709H88.2999V58.154H92.7887V53.9709Z"
        fill="#3F3D56"
      />
      <path
        d="M98.7988 53.9709H94.3101V58.154H98.7988V53.9709Z"
        fill="#3F3D56"
      />
      <path
        d="M92.7887 59.675H88.2999V64.0102H92.7887V59.675Z"
        fill="#3F3D56"
      />
      <path
        d="M98.7988 59.675H94.3101V64.0102H98.7988V59.675Z"
        fill="#3F3D56"
      />
      <path d="M126.706 84.402H123.88V87.0358H126.706V84.402Z" fill="#F2F2F2" />
      <path d="M130.49 84.402H127.664V87.0358H130.49V84.402Z" fill="#F2F2F2" />
      <path
        d="M126.706 87.9937H123.88V90.7233H126.706V87.9937Z"
        fill="#F2F2F2"
      />
      <path
        d="M130.49 87.9937H127.664V90.7233H130.49V87.9937Z"
        fill="#F2F2F2"
      />
      <path
        d="M131.462 94.8515C131.892 94.8515 132.24 94.5035 132.24 94.0741C132.24 93.6447 131.892 93.2966 131.462 93.2966C131.033 93.2966 130.685 93.6447 130.685 94.0741C130.685 94.5035 131.033 94.8515 131.462 94.8515Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 82.6064H71.1907V82.9951H113.964V82.6064Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 84.3554H71.1907V84.7442H113.964V84.3554Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 86.1049H71.1907V86.4937H113.964V86.1049Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 87.854H71.1907V88.2427H113.964V87.854Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 89.6035H71.1907V89.9923H113.964V89.6035Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 91.3526H71.1907V91.7413H113.964V91.3526Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 93.1026H71.1907V93.4913H113.964V93.1026Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 94.8516H71.1907V95.2403H113.964V94.8516Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 96.6012H71.1907V96.9899H113.964V96.6012Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.964 98.3502H71.1907V98.7389H113.964V98.3502Z"
        fill="#F2F2F2"
      />
      <path d="M113.964 100.1H71.1907V100.488H113.964V100.1Z" fill="#F2F2F2" />
      <path
        d="M62.3443 82.6064H39.5966V82.9951H62.3443V82.6064Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 84.3554H39.5966V84.7442H62.3443V84.3554Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 86.1049H39.5966V86.4937H62.3443V86.1049Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 87.854H39.5966V88.2427H62.3443V87.854Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 89.6035H39.5966V89.9923H62.3443V89.6035Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 91.3526H39.5966V91.7413H62.3443V91.3526Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 93.1026H39.5966V93.4913H62.3443V93.1026Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 94.8516H39.5966V95.2403H62.3443V94.8516Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 96.6012H39.5966V96.9899H62.3443V96.6012Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.3443 98.3502H39.5966V98.7389H62.3443V98.3502Z"
        fill="#F2F2F2"
      />
      <path d="M62.3443 100.1H39.5966V100.488H62.3443V100.1Z" fill="#F2F2F2" />
    </svg>
  );
};
export default SingleStorySvg;
