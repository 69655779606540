import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';

import { REACT_APP_BASE_URL } from 'components/common/constants';
import HeaderFooterWrapper from 'components/common/HeaderFooterWrapper';

interface DocumentWrapperProps {
  documentFileLocation: string;
  documentTitle: string;
  documentDownloadName: string;
}

const DocumentWrapper = ({
  documentFileLocation,
  documentTitle,
  documentDownloadName,
}: DocumentWrapperProps): JSX.Element => {
  const fullUrl = `${REACT_APP_BASE_URL}/${documentFileLocation}`;

  const onButtonClick = () => {
    fetch(fullUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');

        alink.href = fileURL;
        alink.download = documentDownloadName;
        alink.click();
      });
    });
  };

  return (
    <HeaderFooterWrapper>
      <div className="py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {documentTitle}
              </h1>
            </div>
          </div>
          <div className="">
            <div className="md:bg-white md:p-6">
              <div className="prose prose-lg text-gray-900 lg:max-w-none">
                <p>
                  You can reach us at help@mullbry.com any time with questions
                  or for assistance.
                </p>
              </div>
              <button
                className="text-base font-medium py-4"
                onClick={onButtonClick}
              >
                Download: {documentTitle} (Printer-friendly PDF)
              </button>
            </div>
          </div>
          <BrowserView>
            <object
              data={`${fullUrl}#zoom=100`}
              width="99%"
              height="1000px"
              title={documentDownloadName}
              type="application/pdf"
            ></object>
          </BrowserView>
          <MobileView>
            <div>
              <p className="prose">
                This browser does not support PDF preview.{' '}
                <u>
                  <a
                    href={`${fullUrl}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Click here to open the PDF in a separate tab
                  </a>
                </u>
              </p>
            </div>
          </MobileView>
        </div>
      </div>
    </HeaderFooterWrapper>
  );
};

export default DocumentWrapper;
