const TransparentPageSvg = (): JSX.Element => {
  return (
    <svg
      width="148"
      height="159"
      viewBox="0 0 148 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.8777 90.2113L29.9027 106.66C27.7326 107.215 25.4304 106.889 23.5013 105.751C21.5723 104.614 20.1737 102.759 19.6126 100.594L0.264906 25.5769C-0.291309 23.4102 0.0359923 21.1116 1.17501 19.1856C2.31402 17.2595 4.17173 15.8631 6.34057 15.3029L65.8589 0L81.0928 6.80913L99.9534 79.9373C100.51 82.104 100.182 84.4026 99.0433 86.3287C97.9042 88.2547 96.0465 89.6511 93.8777 90.2113Z"
        fill="#F2F2F2"
      />
      <path
        d="M6.94593 17.655C5.40144 18.0539 4.07853 19.0483 3.26741 20.4199C2.45629 21.7915 2.22322 23.4284 2.61931 24.9714L21.967 99.9883C22.3666 101.53 23.3625 102.851 24.7363 103.661C26.11 104.471 27.7494 104.704 29.2948 104.308L93.2698 87.8594C94.8143 87.4605 96.1372 86.4661 96.9483 85.0945C97.7594 83.7229 97.9925 82.086 97.5964 80.543L79.0281 8.548L65.6402 2.56396L6.94593 17.655Z"
        fill="white"
      />
      <path
        d="M80.9292 7.1962L71.0282 9.74187C70.3054 9.92771 69.5383 9.81925 68.8955 9.44036C68.2528 9.06146 67.7872 8.44317 67.6011 7.72149L65.718 0.420093C65.7097 0.388127 65.7107 0.354489 65.7207 0.323035C65.7307 0.291582 65.7494 0.263587 65.7746 0.242261C65.7999 0.220934 65.8306 0.207139 65.8634 0.202458C65.8961 0.197778 65.9295 0.2024 65.9597 0.215796L80.9567 6.86564C80.9901 6.88043 81.018 6.90524 81.0366 6.93661C81.0553 6.96797 81.0636 7.00435 81.0606 7.04068C81.0576 7.07702 81.0433 7.11151 81.0198 7.13938C80.9962 7.16725 80.9646 7.18711 80.9292 7.1962Z"
        fill="#F2F2F2"
      />
      <path
        d="M75.7201 71.2589L46.5932 78.7478C46.4143 78.7938 46.228 78.8041 46.045 78.7783C45.8621 78.7524 45.686 78.6908 45.5269 78.597C45.3678 78.5032 45.2287 78.379 45.1177 78.2316C45.0066 78.0841 44.9257 77.9163 44.8797 77.7376C44.8336 77.5589 44.8232 77.3729 44.8491 77.1903C44.875 77.0076 44.9367 76.8318 45.0307 76.6729C45.1246 76.514 45.249 76.3752 45.3967 76.2643C45.5444 76.1534 45.7125 76.0727 45.8914 76.0267L75.0183 68.5378C75.1973 68.4918 75.3836 68.4814 75.5665 68.5073C75.7495 68.5332 75.9256 68.5947 76.0847 68.6886C76.2438 68.7824 76.3829 68.9065 76.4939 69.054C76.605 69.2015 76.6858 69.3693 76.7319 69.548C76.778 69.7267 76.7884 69.9126 76.7625 70.0953C76.7366 70.278 76.6749 70.4538 76.5809 70.6127C76.487 70.7715 76.3626 70.9104 76.2149 71.0212C76.0672 71.1321 75.8991 71.2129 75.7201 71.2589Z"
        fill="#CCCCCC"
      />
      <path
        d="M82.5255 74.4056L47.7776 83.3397C47.4162 83.4326 47.0326 83.3784 46.7112 83.1889C46.3899 82.9995 46.1571 82.6903 46.064 82.3295C45.9709 81.9686 46.0252 81.5857 46.215 81.2648C46.4047 80.9439 46.7144 80.7115 47.0758 80.6186L81.8237 71.6845C82.1849 71.5919 82.5682 71.6464 82.8893 71.8358C83.2104 72.0253 83.443 72.3343 83.536 72.695C83.629 73.0556 83.5748 73.4383 83.3853 73.7591C83.1959 74.0799 82.8866 74.3124 82.5255 74.4056Z"
        fill="#CCCCCC"
      />
      <path
        d="M34.7053 87.7675C37.452 87.7675 39.6787 85.5443 39.6787 82.8019C39.6787 80.0594 37.452 77.8362 34.7053 77.8362C31.9586 77.8362 29.7319 80.0594 29.7319 82.8019C29.7319 85.5443 31.9586 87.7675 34.7053 87.7675Z"
        fill="#E6E6E6"
      />
      <path
        d="M72.6231 58.2634L33.9396 68.2057C32.8217 68.4917 31.6357 68.3233 30.642 67.7373C29.6483 67.1514 28.9279 66.1958 28.639 65.0802L20.5324 33.6367C20.2459 32.5205 20.4146 31.3364 21.0015 30.3442C21.5884 29.3521 22.5454 28.6328 23.6627 28.3443L62.3462 18.402C63.4642 18.116 64.6501 18.2845 65.6438 18.8704C66.6375 19.4564 67.3579 20.412 67.6469 21.5275L75.7535 52.971C76.0399 54.0872 75.8712 55.2713 75.2843 56.2635C74.6975 57.2557 73.7404 57.9749 72.6231 58.2634Z"
        fill="white"
      />
      <path
        d="M72.6231 58.2634L33.9396 68.2057C32.8217 68.4917 31.6357 68.3233 30.642 67.7373C29.6483 67.1514 28.9279 66.1958 28.639 65.0802L20.5324 33.6367C20.2459 32.5205 20.4146 31.3364 21.0015 30.3442C21.5884 29.3521 22.5454 28.6328 23.6627 28.3443L62.3462 18.402C63.4642 18.116 64.6501 18.2845 65.6438 18.8704C66.6375 19.4564 67.3579 20.412 67.6469 21.5275L75.7535 52.971C76.0399 54.0872 75.8712 55.2713 75.2843 56.2635C74.6975 57.2557 73.7404 57.9749 72.6231 58.2634ZM23.7904 28.8395C22.8045 29.0941 21.9601 29.7287 21.4423 30.6041C20.9245 31.4796 20.7756 32.5244 21.0283 33.5093L29.1349 64.9527C29.3899 65.9371 30.0255 66.7802 30.9023 67.2972C31.7791 67.8142 32.8255 67.9629 33.812 67.7105L72.4955 57.7682C73.4813 57.5137 74.3258 56.8791 74.8436 56.0036C75.3614 55.1282 75.5103 54.0834 75.2576 53.0985L67.1509 21.655C66.8959 20.6707 66.2603 19.8275 65.3835 19.3105C64.5067 18.7935 63.4603 18.6449 62.4739 18.8972L23.7904 28.8395Z"
        fill="#E6E6E6"
      />
      <path
        d="M60.3839 28.9746L40.8608 33.9924C40.6901 34.0399 40.5077 34.021 40.3505 33.9395C40.1932 33.8579 40.0728 33.7198 40.0137 33.553C39.9861 33.4659 39.9766 33.3741 39.9858 33.2832C39.995 33.1923 40.0226 33.1042 40.067 33.0243C40.1114 32.9444 40.1716 32.8744 40.244 32.8185C40.3164 32.7626 40.3995 32.7221 40.4881 32.6993L60.3613 27.5916C61.1641 27.9977 60.9529 28.8284 60.3836 28.9747L60.3839 28.9746Z"
        fill="#F2F2F2"
      />
      <path
        d="M61.2654 32.3938L41.7424 37.4116C41.5717 37.4591 41.3893 37.4402 41.232 37.3587C41.0748 37.2771 40.9544 37.139 40.8952 36.9722C40.8677 36.8851 40.8582 36.7933 40.8674 36.7024C40.8766 36.6115 40.9042 36.5234 40.9486 36.4435C40.993 36.3636 41.0532 36.2936 41.1256 36.2377C41.198 36.1818 41.2811 36.1413 41.3697 36.1185L61.2429 31.0107C62.0457 31.4169 61.8345 32.2476 61.2652 32.3939L61.2654 32.3938Z"
        fill="#F2F2F2"
      />
      <path
        d="M38.0239 40.443L32.0657 41.9744C31.8711 42.0242 31.6646 41.9949 31.4917 41.8929C31.3187 41.7909 31.1933 41.6246 31.143 41.4304L29.3331 34.4101C29.2832 34.2158 29.3126 34.0097 29.4147 33.837C29.5169 33.6643 29.6835 33.5391 29.878 33.4889L35.8362 31.9575C36.0308 31.9078 36.2372 31.9371 36.4101 32.0391C36.5831 32.1411 36.7085 32.3074 36.7588 32.5015L38.5688 39.5218C38.6187 39.7161 38.5893 39.9222 38.4872 40.095C38.385 40.2677 38.2184 40.3928 38.0239 40.443V40.443Z"
        fill="#E6E6E6"
      />
      <path
        d="M63.0135 39.409L33.2177 47.067C33.047 47.1146 32.8646 47.0957 32.7074 47.0141C32.5501 46.9326 32.4297 46.7944 32.3706 46.6277C32.343 46.5405 32.3336 46.4487 32.3428 46.3578C32.3519 46.2669 32.3796 46.1788 32.4239 46.0989C32.4683 46.0191 32.5286 45.949 32.601 45.8932C32.6734 45.8373 32.7564 45.7967 32.845 45.7739L62.9912 38.0259C63.794 38.432 63.5827 39.2627 63.0135 39.409L63.0135 39.409Z"
        fill="#F2F2F2"
      />
      <path
        d="M63.8953 42.8292L34.0996 50.4872C33.9289 50.5348 33.7465 50.5159 33.5892 50.4343C33.432 50.3527 33.3116 50.2146 33.2524 50.0478C33.2249 49.9607 33.2154 49.8689 33.2246 49.778C33.2338 49.6871 33.2614 49.599 33.3058 49.5191C33.3502 49.4392 33.4104 49.3692 33.4828 49.3133C33.5552 49.2575 33.6382 49.2169 33.7268 49.1941L63.873 41.446C64.6758 41.8522 64.4646 42.6829 63.8953 42.8292V42.8292Z"
        fill="#F2F2F2"
      />
      <path
        d="M64.7764 46.2472L34.9807 53.9052C34.81 53.9527 34.6276 53.9338 34.4703 53.8523C34.3131 53.7707 34.1927 53.6326 34.1335 53.4658C34.106 53.3787 34.0965 53.2869 34.1057 53.196C34.1149 53.1051 34.1425 53.017 34.1869 52.9371C34.2313 52.8572 34.2915 52.7872 34.3639 52.7313C34.4363 52.6754 34.5193 52.6349 34.608 52.6121L64.7541 44.864C65.5569 45.2701 65.3457 46.1008 64.7764 46.2472Z"
        fill="#F2F2F2"
      />
      <path
        d="M65.658 49.6671L35.8623 57.325C35.6916 57.3726 35.5092 57.3537 35.3519 57.2722C35.1947 57.1906 35.0743 57.0525 35.0151 56.8857C34.9876 56.7986 34.9781 56.7067 34.9873 56.6158C34.9965 56.5249 35.0241 56.4369 35.0685 56.357C35.1129 56.2771 35.1731 56.2071 35.2455 56.1512C35.3179 56.0953 35.4009 56.0548 35.4895 56.032L65.6357 48.2839C66.4385 48.6901 66.2273 49.5208 65.658 49.6671L65.658 49.6671Z"
        fill="#F2F2F2"
      />
      <path
        d="M66.5393 53.0853L36.7436 60.7433C36.5729 60.7909 36.3905 60.772 36.2333 60.6904C36.076 60.6088 35.9556 60.4707 35.8965 60.3039C35.8689 60.2168 35.8595 60.125 35.8686 60.0341C35.8778 59.9432 35.9054 59.8551 35.9498 59.7752C35.9942 59.6953 36.0544 59.6253 36.1268 59.5694C36.1992 59.5136 36.2823 59.473 36.3709 59.4502L66.5171 51.7021C67.3199 52.1083 67.1086 52.939 66.5393 53.0853Z"
        fill="#F2F2F2"
      />
      <path
        d="M35.0723 84.5424C34.964 84.5705 34.8495 84.5626 34.7461 84.52L34.7397 84.5173L33.3848 83.9438C33.3219 83.9173 33.2649 83.8786 33.217 83.83C33.1691 83.7814 33.1313 83.7238 33.1057 83.6606C33.0801 83.5974 33.0673 83.5298 33.0679 83.4616C33.0685 83.3934 33.0826 83.3261 33.1093 83.2633C33.136 83.2006 33.1748 83.1437 33.2236 83.096C33.2723 83.0483 33.33 83.0106 33.3934 82.9851C33.4567 82.9597 33.5245 82.947 33.5928 82.9477C33.661 82.9484 33.7285 82.9625 33.7913 82.9893L34.6692 83.3622L35.8196 80.66C35.8463 80.5974 35.8851 80.5406 35.9338 80.4929C35.9824 80.4452 36.04 80.4076 36.1032 80.3821C36.1665 80.3566 36.2341 80.3439 36.3023 80.3445C36.3705 80.3451 36.4379 80.3592 36.5006 80.3858L36.5008 80.3859L36.4939 80.4036L36.5011 80.3858C36.6277 80.4398 36.7277 80.5417 36.7792 80.6692C36.8307 80.7967 36.8294 80.9393 36.7756 81.0659L35.4222 84.243C35.3908 84.3162 35.3429 84.3813 35.2823 84.4331C35.2217 84.4849 35.1499 84.5221 35.0726 84.5417L35.0723 84.5424Z"
        fill="white"
      />
      <path
        d="M118.415 118.798H52.3526C50.1124 118.796 47.9646 117.906 46.3805 116.324C44.7964 114.743 43.9054 112.598 43.9028 110.362V32.8974C43.9054 30.6606 44.7964 28.5162 46.3805 26.9345C47.9646 25.3529 50.1124 24.4632 52.3526 24.4607H113.813L126.865 34.8478V110.362C126.862 112.598 125.971 114.743 124.387 116.324C122.803 117.906 120.655 118.796 118.415 118.798Z"
        fill="#E6E6E6"
      />
      <path
        d="M52.3527 26.8894C50.7574 26.8912 49.2279 27.5248 48.0999 28.6511C46.9718 29.7774 46.3372 31.3045 46.3354 32.8974V110.362C46.3372 111.954 46.9718 113.482 48.0999 114.608C49.2279 115.734 50.7574 116.368 52.3527 116.37H118.415C120.01 116.368 121.54 115.734 122.668 114.608C123.796 113.482 124.43 111.954 124.432 110.362V36.0178L112.962 26.8894H52.3527Z"
        fill="white"
      />
      <path
        d="M105.566 49.4139H75.4887C75.1155 49.4139 74.7576 49.2658 74.4937 49.0024C74.2298 48.7389 74.0815 48.3815 74.0815 48.0089C74.0815 47.6363 74.2298 47.279 74.4937 47.0155C74.7576 46.752 75.1155 46.604 75.4887 46.604H105.566C105.939 46.604 106.297 46.752 106.561 47.0155C106.825 47.279 106.973 47.6363 106.973 48.0089C106.973 48.3815 106.825 48.7389 106.561 49.0024C106.297 49.2658 105.939 49.4139 105.566 49.4139Z"
        fill="#005CFF"
      />
      <path
        d="M111.37 54.1556H75.4885C75.1156 54.1551 74.7582 54.0068 74.4947 53.7434C74.2312 53.48 74.0833 53.1229 74.0833 52.7506C74.0833 52.3783 74.2312 52.0213 74.4947 51.7579C74.7582 51.4944 75.1156 51.3462 75.4885 51.3457H111.37C111.555 51.3455 111.738 51.3816 111.909 51.4521C112.08 51.5226 112.235 51.626 112.366 51.7565C112.497 51.887 112.601 52.042 112.672 52.2125C112.743 52.3831 112.779 52.566 112.779 52.7506C112.779 52.9353 112.743 53.1181 112.672 53.2887C112.601 53.4593 112.497 53.6143 112.366 53.7447C112.235 53.8752 112.08 53.9787 111.909 54.0492C111.738 54.1197 111.555 54.1558 111.37 54.1556Z"
        fill="#005CFF"
      />
      <path
        d="M105.566 67.853H75.4898C75.1166 67.853 74.7587 68.001 74.4949 68.2645C74.231 68.528 74.0828 68.8853 74.0828 69.2579C74.0828 69.6305 74.231 69.9878 74.4949 70.2512C74.7587 70.5147 75.1166 70.6627 75.4898 70.6627H105.566C105.939 70.6627 106.297 70.5147 106.561 70.2512C106.825 69.9878 106.973 69.6305 106.973 69.2579C106.973 68.8853 106.825 68.528 106.561 68.2645C106.297 68.001 105.939 67.853 105.566 67.853Z"
        fill="#CCCCCC"
      />
      <path
        d="M111.371 72.5955H75.4898C75.1166 72.5955 74.7587 72.7435 74.4949 73.0069C74.231 73.2704 74.0828 73.6277 74.0828 74.0003C74.0828 74.3729 74.231 74.7302 74.4949 74.9937C74.7587 75.2571 75.1166 75.4051 75.4898 75.4051H111.371C111.744 75.4051 112.102 75.2571 112.366 74.9937C112.629 74.7302 112.778 74.3729 112.778 74.0003C112.778 73.6277 112.629 73.2704 112.366 73.0069C112.102 72.7435 111.744 72.5955 111.371 72.5955Z"
        fill="#CCCCCC"
      />
      <path
        d="M105.566 91.9131H75.4887C75.1155 91.9131 74.7576 91.7651 74.4937 91.5016C74.2298 91.2382 74.0815 90.8808 74.0815 90.5082C74.0815 90.1356 74.2298 89.7782 74.4937 89.5148C74.7576 89.2513 75.1155 89.1033 75.4887 89.1033H105.566C105.939 89.1033 106.297 89.2513 106.561 89.5148C106.825 89.7782 106.973 90.1356 106.973 90.5082C106.973 90.8808 106.825 91.2382 106.561 91.5016C106.297 91.7651 105.939 91.9131 105.566 91.9131Z"
        fill="#CCCCCC"
      />
      <path
        d="M111.37 96.6549H75.4885C75.1156 96.6544 74.7582 96.5061 74.4947 96.2427C74.2312 95.9793 74.0833 95.6222 74.0833 95.2499C74.0833 94.8776 74.2312 94.5206 74.4947 94.2571C74.7582 93.9937 75.1156 93.8455 75.4885 93.845H111.37C111.555 93.8447 111.738 93.8809 111.909 93.9514C112.08 94.0219 112.235 94.1253 112.366 94.2558C112.497 94.3863 112.601 94.5412 112.672 94.7118C112.743 94.8824 112.779 95.0653 112.779 95.2499C112.779 95.4346 112.743 95.6174 112.672 95.788C112.601 95.9586 112.497 96.1135 112.366 96.244C112.235 96.3745 112.08 96.478 111.909 96.5484C111.738 96.6189 111.555 96.6551 111.37 96.6549Z"
        fill="#CCCCCC"
      />
      <path
        d="M62.9636 55.3454C65.7104 55.3454 67.937 53.1222 67.937 50.3797C67.937 47.6373 65.7104 45.4141 62.9636 45.4141C60.2169 45.4141 57.9902 47.6373 57.9902 50.3797C57.9902 53.1222 60.2169 55.3454 62.9636 55.3454Z"
        fill="#005CFF"
      />
      <path
        d="M62.4707 52.2742C62.3588 52.2744 62.2499 52.2382 62.1604 52.1712L62.1548 52.1671L60.986 51.2744C60.9317 51.233 60.8861 51.1813 60.8519 51.1223C60.8176 51.0633 60.7953 50.9982 60.7863 50.9306C60.7773 50.863 60.7817 50.7943 60.7993 50.7284C60.8169 50.6625 60.8474 50.6007 60.8889 50.5466C60.9305 50.4924 60.9823 50.447 61.0415 50.4129C61.1007 50.3789 61.166 50.3567 61.2337 50.3479C61.3014 50.339 61.3702 50.3436 61.4362 50.3613C61.5021 50.379 61.5639 50.4096 61.6181 50.4512L62.3751 51.0309L64.1641 48.7005C64.2056 48.6465 64.2573 48.6012 64.3164 48.5671C64.3754 48.5331 64.4406 48.5109 64.5082 48.502C64.5758 48.4931 64.6445 48.4976 64.7103 48.5152C64.7762 48.5328 64.8379 48.5631 64.892 48.6046L64.8922 48.6047L64.8811 48.6201L64.8925 48.6047C65.0017 48.6884 65.0731 48.812 65.0911 48.9483C65.1091 49.0846 65.0722 49.2224 64.9885 49.3316L62.8843 52.0713C62.8356 52.1345 62.773 52.1855 62.7014 52.2206C62.6297 52.2557 62.551 52.2738 62.4712 52.2736L62.4707 52.2742Z"
        fill="white"
      />
      <path
        d="M68.4481 71.6289C68.4484 72.2811 68.32 72.927 68.0702 73.5296C67.8204 74.1322 67.4542 74.6798 66.9924 75.1411C66.5307 75.6024 65.9824 75.9683 65.379 76.218C64.7756 76.4677 64.1288 76.5963 63.4756 76.5964C63.4328 76.5972 63.3901 76.5955 63.3476 76.5912C62.3697 76.5662 61.4209 76.2538 60.6199 75.693C59.819 75.1323 59.2015 74.3482 58.8446 73.4388C58.4878 72.5294 58.4075 71.5351 58.6138 70.5804C58.8201 69.6256 59.3038 68.7528 60.0044 68.0712C60.705 67.3896 61.5914 66.9294 62.5527 66.7483C63.5139 66.5671 64.5073 66.673 65.4086 67.0528C66.3098 67.4325 67.0789 68.0692 67.6196 68.8832C68.1602 69.6971 68.4484 70.6522 68.4481 71.6289Z"
        fill="#CCCCCC"
      />
      <path
        d="M62.9636 97.8449C65.7104 97.8449 67.937 95.6217 67.937 92.8792C67.937 90.1368 65.7104 87.9136 62.9636 87.9136C60.2169 87.9136 57.9902 90.1368 57.9902 92.8792C57.9902 95.6217 60.2169 97.8449 62.9636 97.8449Z"
        fill="#CCCCCC"
      />
      <path
        d="M126.611 35.1821H116.387C115.64 35.1821 114.924 34.886 114.397 34.3591C113.869 33.8321 113.572 33.1174 113.572 32.3722V24.8326C113.572 24.7996 113.582 24.7672 113.599 24.7393C113.617 24.7113 113.642 24.6889 113.672 24.6745C113.701 24.6601 113.735 24.6544 113.767 24.658C113.8 24.6617 113.831 24.6744 113.857 24.6949L126.72 34.8688C126.748 34.8914 126.769 34.9224 126.779 34.9574C126.79 34.9924 126.789 35.0297 126.777 35.0642C126.765 35.0986 126.742 35.1285 126.712 35.1496C126.683 35.1707 126.647 35.1821 126.611 35.1821Z"
        fill="#CCCCCC"
      />
      <path
        d="M68.4481 71.629C68.4484 72.2812 68.3199 72.927 68.0702 73.5296C67.8204 74.1323 67.4542 74.6798 66.9924 75.1411C66.5306 75.6024 65.9824 75.9683 65.379 76.218C64.7755 76.4677 64.1287 76.5963 63.4755 76.5964C63.4328 76.5973 63.3901 76.5955 63.3475 76.5913C62.705 74.9912 62.4573 73.2603 62.6253 71.5446C62.7933 69.8289 63.3721 68.1787 64.3129 66.7332C65.4697 66.9312 66.5193 67.5311 67.276 68.427C68.0327 69.3229 68.4479 70.457 68.4481 71.629Z"
        fill="#005CFF"
      />
      <path
        d="M74.0813 69.2591C74.082 68.8864 74.2305 68.5292 74.4945 68.2656C74.7585 68.0021 75.1163 67.8537 75.4896 67.853H82.9843C83.4221 68.742 83.7332 69.6877 83.9086 70.6627H75.4896C75.1166 70.6626 74.7588 70.5148 74.4948 70.2517C74.2308 69.9885 74.0821 69.6316 74.0813 69.2591Z"
        fill="#005CFF"
      />
      <path
        d="M84.083 72.5955C84.0835 73.5444 83.9578 74.4892 83.7092 75.4051H75.4898C75.1166 75.4051 74.7587 75.2571 74.4949 74.9937C74.231 74.7302 74.0828 74.3729 74.0828 74.0003C74.0828 73.6277 74.231 73.2704 74.4949 73.0069C74.7587 72.7435 75.1166 72.5955 75.4898 72.5955H84.083Z"
        fill="#005CFF"
      />
      <path
        d="M102.627 89.9232C102.415 90.2705 102.073 90.5194 101.677 90.6152C101.281 90.711 100.863 90.6458 100.515 90.434L82.5901 79.5173C82.4178 79.4124 82.268 79.2747 82.149 79.112C82.0301 78.9492 81.9445 78.7647 81.897 78.569C81.8494 78.3732 81.841 78.17 81.8722 77.9709C81.9033 77.7719 81.9734 77.581 82.0785 77.409C82.1836 77.237 82.3215 77.0874 82.4845 76.9686C82.6475 76.8499 82.8323 76.7644 83.0283 76.717C83.2244 76.6695 83.428 76.6611 83.6273 76.6922C83.8266 76.7233 84.0179 76.7933 84.1901 76.8982L102.115 87.8149C102.463 88.0267 102.712 88.3679 102.808 88.7633C102.904 89.1586 102.839 89.5759 102.627 89.9232Z"
        fill="#3F3D56"
      />
      <path
        d="M84.7018 79.0065C83.436 81.0786 81.5832 82.7293 79.3777 83.75C77.1723 84.7707 74.7132 85.1154 72.3115 84.7407C69.9098 84.366 67.6733 83.2886 65.8849 81.6448C64.0964 80.0009 62.8364 77.8645 62.264 75.5057C61.6917 73.1468 61.8327 70.6715 62.6694 68.3927C63.506 66.1139 65.0007 64.134 66.9643 62.7033C68.928 61.2727 71.2724 60.4555 73.7012 60.3552C76.13 60.2549 78.534 60.876 80.6092 62.1398C83.3892 63.8372 85.3813 66.5662 86.1486 69.7282C86.9158 72.8903 86.3955 76.227 84.7018 79.0065ZM66.3395 67.8236C65.3902 69.3776 64.9237 71.1779 64.999 72.9966C65.0743 74.8154 65.6881 76.571 66.7628 78.0414C67.8375 79.5119 69.3247 80.6311 71.0365 81.2577C72.7482 81.8842 74.6076 81.9898 76.3795 81.5612C78.1514 81.1326 79.7562 80.189 80.991 78.8498C82.2257 77.5105 83.035 75.8358 83.3165 74.0373C83.598 72.2388 83.339 70.3973 82.5723 68.7458C81.8056 67.0943 80.5657 65.7069 79.0092 64.759C76.9214 63.4906 74.4149 63.101 72.0397 63.6756C69.6645 64.2501 67.6145 65.7419 66.3395 67.8236Z"
        fill="#3F3D56"
      />
      <path
        d="M124.081 74.5838C127.554 74.5838 130.37 71.7725 130.37 68.3046C130.37 64.8367 127.554 62.0254 124.081 62.0254C120.607 62.0254 117.792 64.8367 117.792 68.3046C117.792 71.7725 120.607 74.5838 124.081 74.5838Z"
        fill="#A0616A"
      />
      <path
        d="M123.674 103.507C123.553 103.129 123.513 102.731 123.557 102.337C123.601 101.943 123.727 101.563 123.928 101.221C124.13 100.88 124.401 100.584 124.724 100.355C125.048 100.125 125.416 99.9662 125.805 99.8886C125.929 99.8646 126.054 99.8492 126.179 99.8427L132.804 89.2269L126.752 83.4831C126.521 83.2636 126.336 83.0006 126.207 82.7092C126.078 82.4179 126.008 82.1039 126.002 81.7854C125.995 81.467 126.052 81.1504 126.168 80.8539C126.285 80.5574 126.459 80.2869 126.681 80.0581C126.903 79.8293 127.168 79.6466 127.461 79.5206C127.754 79.3946 128.069 79.3278 128.388 79.3241C128.707 79.3204 129.024 79.3799 129.32 79.499C129.615 79.6181 129.885 79.7946 130.112 80.0182L138.467 88.2437L138.484 88.2642C138.742 88.6568 138.867 89.1222 138.839 89.5912C138.812 90.0603 138.634 90.508 138.331 90.8679L129.049 101.829C129.077 101.917 129.1 102.006 129.119 102.096C129.196 102.485 129.191 102.885 129.102 103.272C129.014 103.658 128.845 104.022 128.606 104.338C128.368 104.655 128.065 104.917 127.717 105.109C127.37 105.3 126.986 105.416 126.59 105.449C126.51 105.456 126.431 105.46 126.351 105.46C125.756 105.457 125.177 105.267 124.696 104.916C124.215 104.566 123.858 104.072 123.674 103.507Z"
        fill="#A0616A"
      />
      <path
        d="M113.723 155.696L110.584 155.696L109.09 143.606L113.723 143.606L113.723 155.696Z"
        fill="#A0616A"
      />
      <path
        d="M114.523 158.734L104.401 158.734V158.606C104.401 158.089 104.503 157.578 104.701 157.1C104.899 156.623 105.189 156.189 105.555 155.824C105.921 155.459 106.355 155.169 106.833 154.972C107.311 154.774 107.824 154.672 108.341 154.672H108.341L114.524 154.672L114.523 158.734Z"
        fill="#2F2E41"
      />
      <path
        d="M144.925 151.362L142.227 152.966L134.749 143.341L138.73 140.975L144.925 151.362Z"
        fill="#A0616A"
      />
      <path
        d="M147.169 153.564L138.472 158.734L138.407 158.624C138.142 158.18 137.967 157.689 137.893 157.177C137.819 156.666 137.846 156.145 137.973 155.645C138.1 155.144 138.325 154.673 138.634 154.259C138.944 153.845 139.332 153.496 139.776 153.232L139.776 153.231L145.088 150.074L147.169 153.564Z"
        fill="#2F2E41"
      />
      <path
        d="M108.694 150.372C106.591 125.648 105.34 102.68 113.028 93.3104L113.087 93.2378L126.025 98.4049L126.046 98.4511C126.09 98.5459 130.392 107.996 129.388 114.352L132.58 129.008L142.985 146.403C143.068 146.543 143.121 146.699 143.14 146.861C143.159 147.023 143.143 147.188 143.093 147.343C143.043 147.498 142.961 147.641 142.852 147.763C142.743 147.884 142.609 147.981 142.459 148.046L137.938 150.033C137.683 150.144 137.396 150.159 137.13 150.076C136.865 149.993 136.638 149.818 136.491 149.581L125.173 131.154L118.78 117.019C118.745 116.942 118.686 116.879 118.612 116.839C118.537 116.8 118.452 116.785 118.369 116.799C118.285 116.812 118.209 116.852 118.151 116.913C118.093 116.974 118.057 117.053 118.048 117.137L114.504 150.397C114.474 150.679 114.341 150.941 114.129 151.131C113.917 151.321 113.643 151.426 113.358 151.426H109.843C109.554 151.424 109.277 151.315 109.065 151.12C108.852 150.925 108.72 150.658 108.694 150.372Z"
        fill="#2F2E41"
      />
      <path
        d="M113.071 93.5065L113.01 93.477L113 93.4093C112.517 90.0321 113.089 86.2796 114.7 82.2563C115.495 80.2871 116.971 78.6684 118.86 77.695C120.75 76.7216 122.927 76.4583 124.994 76.9531C126.185 77.2462 127.302 77.7829 128.275 78.5295C129.247 79.276 130.053 80.2161 130.643 81.2901C131.225 82.3531 131.579 83.5252 131.684 84.7321C131.789 85.939 131.641 87.1544 131.251 88.3014C129.219 94.2327 126.584 99.7823 126.557 99.8377L126.502 99.9536L113.071 93.5065Z"
        fill="#005CFF"
      />
      <path
        d="M98.9561 85.0945C99.2676 84.849 99.6276 84.6719 100.012 84.5749C100.397 84.4779 100.798 84.4632 101.189 84.5318C101.58 84.6003 101.952 84.7506 102.281 84.9727C102.609 85.1948 102.887 85.4837 103.096 85.8205C103.162 85.9275 103.22 86.0388 103.27 86.1539L115.549 88.6318L118.819 80.9611C118.944 80.6681 119.125 80.4026 119.354 80.18C119.582 79.9575 119.852 79.7823 120.148 79.6646C120.445 79.5469 120.762 79.489 121.081 79.4942C121.4 79.4995 121.714 79.5677 122.007 79.6951C122.299 79.8225 122.563 80.0065 122.784 80.2364C123.005 80.4663 123.178 80.7376 123.293 81.0346C123.408 81.3316 123.463 81.6485 123.455 81.9669C123.447 82.2853 123.376 82.599 123.246 82.8897L118.455 93.584L118.442 93.6069C118.164 93.9861 117.771 94.2657 117.321 94.4043C116.872 94.5429 116.389 94.5331 115.946 94.3763L102.412 89.534C102.34 89.5907 102.264 89.6435 102.186 89.6923C101.848 89.901 101.471 90.0361 101.077 90.0889C100.684 90.1416 100.284 90.1108 99.9031 89.9984C99.5225 89.886 99.17 89.6946 98.8686 89.4366C98.5673 89.1787 98.3239 88.8602 98.1545 88.5018C98.1199 88.4295 98.0888 88.3563 98.0611 88.2822C97.8549 87.7245 97.8304 87.116 97.9911 86.5435C98.1519 85.9711 98.4896 85.464 98.9561 85.0945Z"
        fill="#A0616A"
      />
      <path
        d="M146.8 75.0292C146.194 73.9575 145.312 72.976 144.145 72.5798C142.638 72.0682 141.007 72.613 139.457 72.9766C138.264 73.2568 137.017 73.4283 135.814 73.1919C134.611 72.9554 133.45 72.2564 132.903 71.1609C132.097 69.5493 132.776 67.6276 132.727 65.8272C132.702 64.8472 132.454 63.8857 132.002 63.0151C131.551 62.1445 130.907 61.3877 130.12 60.8015C129.333 60.2154 128.423 59.8153 127.459 59.6314C126.494 59.4475 125.501 59.4846 124.553 59.7399C123.069 59.3124 121.726 59.2729 120.809 60.4115C119.654 60.4115 118.547 60.8694 117.731 61.6845C116.914 62.4996 116.456 63.605 116.456 64.7577H120.618C120.421 65.8174 120.646 66.9121 121.246 67.808C121.981 68.8858 123.259 69.6655 123.505 70.9459C123.743 72.1868 122.898 73.3641 122.012 74.2661C121.126 75.168 120.098 76.0618 119.821 77.2947C119.652 78.2306 119.844 79.1959 120.36 79.9956C120.875 80.7856 121.513 81.4879 122.251 82.0756C125.576 84.9197 129.528 86.9381 133.783 87.966C136.78 88.6864 140.058 88.8732 142.84 87.5479C143.953 87.0177 144.945 86.2648 145.754 85.3357C146.563 84.4067 147.173 83.3214 147.544 82.1474C147.915 80.9734 148.041 79.7357 147.913 78.5111C147.785 77.2866 147.406 76.1014 146.8 75.0292Z"
        fill="#2F2E41"
      />
      <path
        d="M147.74 159H99.0896C99.0216 159 98.9565 158.973 98.9085 158.925C98.8605 158.877 98.8335 158.812 98.8335 158.744C98.8335 158.676 98.8605 158.611 98.9085 158.563C98.9565 158.515 99.0216 158.489 99.0896 158.489H147.74C147.808 158.489 147.873 158.515 147.921 158.563C147.969 158.611 147.996 158.676 147.996 158.744C147.996 158.812 147.969 158.877 147.921 158.925C147.873 158.973 147.808 159 147.74 159Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default TransparentPageSvg;
