const ApprovedGuySvg = (): JSX.Element => {
  return (
    <svg
      width="119"
      height="104"
      viewBox="0 0 119 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.9583 96.5625C79.587 96.5768 74.221 96.2252 68.8976 95.5101C68.7238 95.488 68.5476 95.4631 68.3726 95.4371C67.0659 95.2512 65.7585 95.0388 64.4876 94.8057C58.768 93.7597 53.3744 92.232 48.4566 90.2649C32.0914 83.7186 21.8019 85.1119 16.0519 87.4332C9.82917 89.9456 7.43713 94.056 7.41368 94.0973L7.209 93.9811C7.23273 93.9392 9.65563 89.7676 15.9463 87.2221C19.6347 85.7296 23.9135 85.0774 28.6649 85.2829C34.5958 85.5397 41.2841 87.1423 48.5441 90.0463C53.4473 92.0076 58.8258 93.531 64.5299 94.5742C65.7978 94.8067 67.1021 95.0187 68.4065 95.2041C68.5807 95.2301 68.7553 95.2547 68.9286 95.2768C83.3589 97.2618 97.2797 96.0648 106.417 94.7112C113.949 93.5955 118.796 92.291 118.844 92.278L118.906 92.5051C118.857 92.5182 113.998 93.8261 106.452 94.944C99.336 96.0038 92.1525 96.5447 84.9583 96.5625Z"
        fill="#3F3D56"
      />
      <path
        d="M84.9743 29.0162C80.8255 29.0163 76.7865 28.8221 72.8896 28.434C63.9349 27.5422 55.7145 25.618 48.4566 22.7149C32.0914 16.1687 21.8019 17.5618 16.0519 19.8833C9.82917 22.3956 7.43713 26.506 7.41368 26.5472L7.209 26.431C7.23273 26.3892 9.65563 22.2177 15.9463 19.6722C19.6347 18.1796 23.9135 17.5271 28.6649 17.7329C34.5958 17.9897 41.2841 19.5923 48.5441 22.4963C64.9351 29.0528 83.1744 29.3104 95.589 28.3728C109.045 27.3561 118.748 24.7542 118.844 24.728L118.906 24.9551C118.809 24.9814 109.089 27.5883 95.6118 28.6071C92.0033 28.8799 88.4502 29.0162 84.9743 29.0162Z"
        fill="#3F3D56"
      />
      <path
        d="M105.327 37.6019H72.3755V78.791H105.327V37.6019Z"
        fill="#E6E6E6"
      />
      <path d="M81.7315 41.0737H74.6705V45.781H81.7315V41.0737Z" fill="white" />
      <path
        d="M102.032 50.2526H74.7294V51.9002H102.032V50.2526Z"
        fill="white"
      />
      <path
        d="M102.032 54.6071H74.7294V56.2547H102.032V54.6071Z"
        fill="white"
      />
      <path
        d="M102.032 58.9615H74.7294V60.6091H102.032V58.9615Z"
        fill="white"
      />
      <path
        d="M102.032 63.3155H74.7294V64.9631H102.032V63.3155Z"
        fill="white"
      />
      <path
        d="M102.032 67.6699H74.7294V69.3175H102.032V67.6699Z"
        fill="white"
      />
      <path
        d="M111.8 78.7322C111.798 80.2866 111.236 81.7882 110.216 82.9612C109.196 84.1341 107.787 84.8996 106.248 85.1171C104.709 85.3345 103.143 84.9892 101.838 84.1446C100.533 83.3001 99.5771 82.013 99.1451 80.5199C98.7131 79.0268 98.8345 77.4279 99.4869 76.0171C100.139 74.6063 101.279 73.4783 102.696 72.8404C104.114 72.2024 105.714 72.0974 107.202 72.5447C108.691 72.9919 109.968 73.9613 110.799 75.2747C111.453 76.3095 111.8 77.5083 111.8 78.7322Z"
        fill="#005CFF"
      />
      <path
        d="M110.799 75.2749L104.428 81.645C104.028 81.0251 101.797 78.144 101.797 78.144C102.083 77.855 102.388 77.5857 102.71 77.3378L104.56 79.8045L110.053 74.311C110.332 74.608 110.582 74.9307 110.799 75.2749Z"
        fill="white"
      />
      <path
        d="M73.9652 73.6792C73.9652 73.6792 87.6828 74.3884 84.4877 78.7067C81.2926 83.0249 72.8969 76.1735 72.8969 76.1735L73.9652 73.6792Z"
        fill="#FFB8B8"
      />
      <path
        d="M48.6861 32.548L51.4783 33.246C51.4783 33.246 53.107 38.1323 54.2704 42.5532C55.4338 46.974 57.7606 60.2367 57.7606 60.2367L75.7995 72.8789L73.648 77.6918L49.6169 68.2208L44.2652 47.2067L48.6861 32.548Z"
        fill="#575A89"
      />
      <path
        opacity="0.2"
        d="M51.313 41.2211L50.2723 63.0138L52.4438 69.493L46.0827 64.8282L51.313 41.2211Z"
        fill="black"
      />
      <path
        d="M55.0846 79.8984C55.0846 79.8984 58.1094 88.0422 58.5748 93.6265C59.0401 99.2107 59.5055 103.632 59.5055 103.632H45.3121L42.52 99.4434V103.632H25.9998C25.9998 103.632 21.3462 88.5075 23.2076 86.6461C25.0691 84.7847 42.7526 76.8736 42.7526 76.8736L55.0846 79.8984Z"
        fill="#2F2E41"
      />
      <path
        d="M82.1294 103.42H-0.000427246V103.702H82.1294V103.42Z"
        fill="#3F3D56"
      />
      <path
        d="M37.2847 23.7062C42.1679 23.7062 46.1265 19.7476 46.1265 14.8644C46.1265 9.98119 42.1679 6.02258 37.2847 6.02258C32.4015 6.02258 28.4429 9.98119 28.4429 14.8644C28.4429 19.7476 32.4015 23.7062 37.2847 23.7062Z"
        fill="#FFB8B8"
      />
      <path
        d="M30.5371 18.5874L29.8391 28.8253L38.6809 40.6919L43.1018 29.5233C43.1018 29.5233 39.1462 26.9638 41.473 21.6122L30.5371 18.5874Z"
        fill="#FFB8B8"
      />
      <path
        d="M56.3639 81.876C56.3639 83.971 50.547 87.2279 50.547 87.2279V88.3913L48.2202 89.5547L43.1016 79.3174L41.7064 90.4848C41.7064 90.4848 31.4677 92.1147 28.2108 90.4848C24.9524 88.8564 23.324 89.5547 18.6704 87.9262C14.0169 86.2978 20.5321 64.4252 20.5321 64.4252L15.8786 30.687L26.0451 25.6037L26.5809 25.3351L27.9789 25.0778L30.122 24.682L37.2846 29.5236L37.8614 32.5487L38.2162 34.4104L40.0765 32.3154L41.4816 27.6944L51.4785 33.247L48.6867 55.5832C48.6867 55.5832 56.3639 79.7825 56.3639 81.876Z"
        fill="#575A89"
      />
      <path
        d="M22.1607 89.089C22.1607 89.089 32.8639 97.6981 27.7449 99.3268C22.626 100.956 19.8339 90.4851 19.8339 90.4851L22.1607 89.089Z"
        fill="#FFB8B8"
      />
      <path
        d="M18.2049 31.1521L15.8781 30.6867C15.8781 30.6867 12.3879 32.5481 12.1553 36.271C11.9226 39.9939 8.89775 66.0539 8.89775 66.0539L18.6703 92.3465L26.5813 89.3217L18.9029 64.6578L24.7199 43.2514L18.2049 31.1521Z"
        fill="#575A89"
      />
      <path
        d="M38.3078 38.2526C38.7763 38.2526 39.156 37.8729 39.156 37.4044C39.156 36.936 38.7763 36.5563 38.3078 36.5563C37.8394 36.5563 37.4597 36.936 37.4597 37.4044C37.4597 37.8729 37.8394 38.2526 38.3078 38.2526Z"
        fill="#2F2E41"
      />
      <path
        d="M38.3078 43.7655C38.7763 43.7655 39.156 43.3857 39.156 42.9173C39.156 42.4489 38.7763 42.0692 38.3078 42.0692C37.8394 42.0692 37.4597 42.4489 37.4597 42.9173C37.4597 43.3857 37.8394 43.7655 38.3078 43.7655Z"
        fill="#2F2E41"
      />
      <path
        d="M43.1142 80.0951C43.5827 80.0951 43.9624 79.7154 43.9624 79.247C43.9624 78.7785 43.5827 78.3988 43.1142 78.3988C42.6458 78.3988 42.2661 78.7785 42.2661 79.247C42.2661 79.7154 42.6458 80.0951 43.1142 80.0951Z"
        fill="#2F2E41"
      />
      <path
        d="M29.8196 5.80662L28.1852 5.15228C28.1852 5.15228 31.6027 1.38981 36.3575 1.71699L35.0202 0.244717C35.0202 0.244717 38.2891 -1.06395 41.2608 2.37133C42.823 4.17717 44.6304 6.29986 45.7572 8.69104H47.5076L46.7771 10.2996L49.334 11.9082L46.7095 11.6193C46.8659 12.5001 46.8899 13.3994 46.7808 14.2874C46.6682 15.1756 46.2149 15.9854 45.5166 16.5458C45.5166 16.5458 43.4896 12.35 43.4896 11.6957V13.3315C43.4896 13.3315 41.8551 11.8593 41.8551 10.8778L40.9636 12.0229H35.0202L35.9117 10.5506L32.4942 11.0413L33.8315 9.2419L28.5403 16.6724C28.5403 16.6724 25.5106 8.26039 29.8196 5.80662Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.2"
        d="M20.992 71.368L26.6457 89.0285L22.1222 70.6517L20.992 71.368Z"
        fill="black"
      />
    </svg>
  );
};

export default ApprovedGuySvg;
