import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import { Prospect } from 'components/common/api/types/prospect';
import { API_BASE_URL_BACKEND } from 'components/common/constants';
import EmailInputSurveyTrigger from 'components/common/EmailInputSurveyTrigger';

const AlwaysExpanding = (): JSX.Element => {
  const [showFailedZipCoverage, setShowFailedZipCoverage] = useState(false);
  const [showSuccessZipCoverage, setShowSuccessZipCoverage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [zipCode, setZipCode] = useState<number>();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [showFailedZipCoverage, showSuccessZipCoverage]);

  const onSubmit = async (formData: any): Promise<void> => {
    const zipCodePayload = {
      zipCode: formData.areaCode,
    };

    setShowLoader(true);

    setZipCode(zipCodePayload.zipCode);

    const getZipCode = axios.get(
      `${API_BASE_URL_BACKEND}/community/zip-code/${formData.areaCode}`,
    );
    const postZipCode = axios.post(
      `${API_BASE_URL_BACKEND}/prospect/zip-code`,
      zipCodePayload,
    );

    const [isActiveZipCode] = await Promise.all([getZipCode, postZipCode]).then(
      (responses) => {
        return [responses[0].data];
      },
    );

    if (isActiveZipCode) {
      setShowSuccessZipCoverage(true);
    } else {
      setShowFailedZipCoverage(true);
    }
  };

  const attachZipCodeToProspect = async (
    newProspect: Prospect,
  ): Promise<void> => {
    const updatedProspect: Prospect = {
      ...newProspect,
      zipCode: zipCode,
    };

    await axios.put(
      `${API_BASE_URL_BACKEND}/prospect/${updatedProspect!.id}`,
      updatedProspect,
    );
  };

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {!showFailedZipCoverage && !showSuccessZipCoverage && (
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">We're always expanding our network!</span>
            </h2>
            <span className="text-2x1 text-gray-900 font-semibold block sm:text-2xl">
              See if we're available in your area now.
            </span>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex">
                <div className="mt-4 sm:flex sm:max-w-md">
                  <label htmlFor="areaCode" className="sr-only">
                    Area Code
                  </label>
                  <input
                    type="number"
                    name="areaCode"
                    ref={register({ valueAsNumber: true })}
                    id="areaCode"
                    required
                    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:placeholder-gray-400"
                    placeholder="Area Code"
                  />
                  <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="w-full bg-blue-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Go
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSuccessZipCoverage && !showLoader && (
          <div>
            <p className="font-semibold">
              Congrats! We're available in your area
            </p>
            <p className="font-semibold">
              You can apply now or enter your email below and we'll reach out to
              you.
            </p>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex">
                <EmailInputSurveyTrigger
                  onEmailSubmit={attachZipCodeToProspect}
                  onSurveySubmit={() => {
                    setShowFailedZipCoverage(false);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {(showFailedZipCoverage || showSuccessZipCoverage) && showLoader && (
          <CircularProgress />
        )}
        {showFailedZipCoverage && !showLoader && (
          <div>
            <p className="font-semibold">Shoot - we're not quite there yet!</p>
            <p className="font-semibold">
              Enter your email below and we'll notify you once we become
              available in your area.
            </p>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex">
                <EmailInputSurveyTrigger
                  onEmailSubmit={attachZipCodeToProspect}
                  onSurveySubmit={() => {
                    setShowFailedZipCoverage(false);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlwaysExpanding;
