export enum SurveyFlowType {
  BuyAHome = 'BuyAHome',
  Refinance = 'Refinance',
}

export enum MobileHomeType {
  SingleWide = 'SingleWide',
  DoubleWide = 'DoubleWide',
}

export enum HomeAgeCategory {
  LessThan3Years = 'LessThan3Years',
  Between3And9Years = 'Between3And9Years',
  Between10And19Years = 'Between10And19Years',
  TwentyOrMoreYears = 'TwentyOrMoreYears',
}

export interface SurveyResult {
  surveyFlowType?: SurveyFlowType;
  mobileHomeType?: MobileHomeType;
  homeAge?: HomeAgeCategory;
}
