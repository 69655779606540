const RefinanceSvg = (): JSX.Element => {
  return (
    <svg
      width="136"
      height="122"
      viewBox="0 0 136 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.0067 70.6112L67.3323 48.3951L29.8474 48.7377L7.10846 70.886L7.56791 71.1812H7.40387V117.25H85.9563V71.1812L86.0067 70.6112Z"
        fill="#3F3D56"
      />
      <path
        d="M67.3519 48.4427L44.0225 75.794V117.25H85.9564V70.591L67.3519 48.4427Z"
        fill="#005CFF"
      />
      <path
        d="M64.4192 94.3242H55.8552V101.894H64.4192V94.3242Z"
        fill="white"
      />
      <path d="M76.564 94.1021H68V101.672H76.564V94.1021Z" fill="white" />
      <path
        d="M64.4192 81.2222H55.8552V88.6717H64.4192V81.2222Z"
        fill="white"
      />
      <path d="M76.564 81H68V88.4495H76.564V81Z" fill="white" />
      <path d="M35 85H26V101H35V85Z" fill="white" />
      <path d="M21 85H13V101H21V85Z" fill="white" />
      <path d="M135.309 117.106H0V117.428H135.309V117.106Z" fill="#3F3D56" />
      <path
        d="M114.931 112.019L115.575 115.239L112.355 115.883L112.838 111.858L114.931 112.019Z"
        fill="#A0616A"
      />
      <path
        d="M125.073 111.375L124.268 115.4H120.404L122.014 110.57L125.073 111.375Z"
        fill="#A0616A"
      />
      <path
        d="M128.615 83.3634L129.259 87.7101L128.454 97.2082L127.81 100.75L125.073 112.341C125.073 112.341 122.014 112.341 121.532 111.053L122.336 104.453C122.336 104.453 122.98 99.1401 122.819 98.4961C122.658 97.8522 120.244 87.5491 120.244 87.5491C120.244 87.5491 116.702 103.809 116.219 105.258C115.736 106.706 115.092 112.985 115.092 112.985C115.092 112.985 112.355 113.468 112.194 112.985C112.033 112.502 111.872 101.072 111.872 99.301C111.872 97.5302 112.194 96.5643 112.194 96.2423C112.194 95.9203 110.926 80.5507 110.926 80.5507L128.615 83.3634Z"
        fill="#2F2E41"
      />
      <path
        d="M122.014 114.595C122.014 114.595 122.819 114.756 123.302 114.595C123.785 114.434 123.302 113.468 123.302 113.468H125.073C125.073 113.468 127.005 117.815 127.81 118.137C128.615 118.458 131.19 122 128.132 122C126.244 121.976 124.402 121.418 122.819 120.39C122.819 120.39 122.497 118.941 121.048 118.78C119.599 118.619 118.634 116.849 118.634 116.849L120.726 112.985C120.726 112.985 121.209 114.756 122.014 114.595Z"
        fill="#2F2E41"
      />
      <path
        d="M114.448 114.434C114.448 114.434 114.126 115.078 113.804 114.756C113.482 114.434 113.16 113.629 113.16 113.629C113.16 113.629 112.194 113.146 112.033 113.629C111.872 114.112 111.228 116.687 110.906 117.009C110.584 117.331 107.203 120.712 110.584 121.356C113.965 122 114.609 120.551 114.609 120.551C114.609 120.551 114.126 119.746 115.092 119.424C116.058 119.102 116.219 117.975 116.219 117.975L115.575 113.146C115.575 113.146 114.931 114.756 114.448 114.434Z"
        fill="#2F2E41"
      />
      <path
        d="M132.318 72.0945L132.801 80.4658C132.801 80.4658 135.054 85.7783 131.996 85.6173C128.937 85.4563 130.869 80.3048 130.869 80.3048L127.874 72.8335L132.318 72.0945Z"
        fill="#A0616A"
      />
      <path
        d="M107.526 69.6797L107.043 78.051C107.043 78.051 104.789 83.3635 107.848 83.2025C110.906 83.0415 108.974 77.89 108.974 77.89L111.969 70.4187L107.526 69.6797Z"
        fill="#A0616A"
      />
      <path
        d="M120.404 51.4882C122.716 51.4882 124.59 49.6142 124.59 47.3026C124.59 44.9909 122.716 43.1169 120.404 43.1169C118.093 43.1169 116.219 44.9909 116.219 47.3026C116.219 49.6142 118.093 51.4882 120.404 51.4882Z"
        fill="#A0616A"
      />
      <path
        d="M123.705 48.9928C123.705 48.9928 122.578 53.5004 124.831 54.6273C127.085 55.7542 117.748 57.3641 117.104 54.6273L117.426 53.3394C117.426 53.3394 118.714 51.7296 118.392 50.2807L123.705 48.9928Z"
        fill="#A0616A"
      />
      <path
        d="M121.209 54.7078C121.209 54.7078 118.432 54.9672 117.567 53.1471C117.567 53.1471 111.067 53.5809 109.618 59.5374L112.033 74.992C112.033 74.992 109.457 79.4996 109.779 79.9826C109.779 79.9826 108.17 84.4902 122.98 84.6512L129.42 84.3292C129.507 83.4141 129.452 82.491 129.259 81.5924C128.937 80.1436 127.166 70.0014 127.166 70.0014C127.166 70.0014 131.995 62.4351 131.513 61.3082C131.03 60.1813 130.256 55.738 124.042 53.935C124.042 53.935 123.946 54.8688 121.209 54.7078Z"
        fill="#E6E6E6"
      />
      <path
        d="M129.42 58.5716L131.512 61.3084C131.512 61.3084 133.605 72.7384 132.961 72.8994C132.317 73.0604 128.454 74.3483 127.81 73.8653C127.166 73.3824 124.268 63.8842 124.268 63.8842L129.42 58.5716Z"
        fill="#E6E6E6"
      />
      <path
        d="M112.355 57.7668L109.618 59.5377C109.618 59.5377 105.594 70.8067 106.721 71.2897C107.848 71.7726 114.126 71.4506 114.126 71.4506L112.355 57.7668Z"
        fill="#E6E6E6"
      />
      <path
        d="M124.98 43.5569C124.98 43.5569 123.53 40.4157 120.751 41.1406C117.972 41.8655 116.402 42.9528 116.281 44.0401C116.16 45.1275 116.341 46.7585 116.341 46.7585C116.341 46.7585 116.643 44.5234 118.576 45.0067C119.605 45.2637 120.94 45.2815 121.966 45.2418C122.167 45.2336 122.367 45.2663 122.555 45.3382C122.743 45.41 122.914 45.5194 123.058 45.6596C123.202 45.7999 123.317 45.968 123.394 46.1539C123.471 46.3397 123.509 46.5392 123.506 46.7404L123.456 50.2004C123.456 50.2004 127.48 47.6247 124.98 43.5569Z"
        fill="#2F2E41"
      />
      <path
        d="M105.238 44.7749H48.3802C48.101 44.7746 47.8334 44.6635 47.636 44.4661C47.4386 44.2687 47.3276 44.0011 47.3272 43.722V17.6147C47.3276 17.3355 47.4386 17.0679 47.636 16.8705C47.8334 16.6731 48.101 16.5621 48.3802 16.5618H105.238C105.517 16.5621 105.785 16.6731 105.982 16.8705C106.18 17.0679 106.291 17.3355 106.291 17.6147V43.722C106.291 44.0011 106.18 44.2687 105.982 44.4661C105.785 44.6635 105.517 44.7746 105.238 44.7749ZM48.3802 16.9829C48.2127 16.9831 48.0521 17.0497 47.9336 17.1682C47.8152 17.2866 47.7486 17.4472 47.7484 17.6147V43.722C47.7486 43.8895 47.8152 44.05 47.9336 44.1685C48.0521 44.2869 48.2127 44.3535 48.3802 44.3537H105.238C105.405 44.3535 105.566 44.2869 105.685 44.1685C105.803 44.05 105.87 43.8895 105.87 43.722V17.6147C105.87 17.4472 105.803 17.2866 105.685 17.1682C105.566 17.0497 105.405 16.9831 105.238 16.9829H48.3802Z"
        fill="#E6E6E6"
      />
      <path
        d="M56.7935 30.0994C59.1623 30.0994 61.0825 28.1791 61.0825 25.8103C61.0825 23.4416 59.1623 21.5213 56.7935 21.5213C54.4247 21.5213 52.5045 23.4416 52.5045 25.8103C52.5045 28.1791 54.4247 30.0994 56.7935 30.0994Z"
        fill="#005CFF"
      />
      <path
        d="M66.6993 22.951C66.6053 22.9508 66.5122 22.9692 66.4254 23.0051C66.3385 23.0409 66.2596 23.0936 66.1931 23.16C66.1266 23.2264 66.0739 23.3052 66.0379 23.392C66.0019 23.4788 65.9834 23.5719 65.9834 23.6658C65.9834 23.7598 66.0019 23.8528 66.0379 23.9396C66.0739 24.0264 66.1266 24.1053 66.1931 24.1717C66.2596 24.2381 66.3385 24.2907 66.4254 24.3266C66.5122 24.3624 66.6053 24.3808 66.6993 24.3807H100.399C100.588 24.3807 100.77 24.3053 100.904 24.1713C101.038 24.0372 101.114 23.8554 101.114 23.6658C101.114 23.4762 101.038 23.2944 100.904 23.1603C100.77 23.0263 100.588 22.951 100.399 22.951H66.6993Z"
        fill="#E6E6E6"
      />
      <path
        d="M66.6993 27.24C66.6053 27.2399 66.5122 27.2583 66.4254 27.2942C66.3385 27.33 66.2596 27.3826 66.1931 27.449C66.1266 27.5154 66.0739 27.5943 66.0379 27.6811C66.0019 27.7679 65.9834 27.8609 65.9834 27.9549C65.9834 28.0489 66.0019 28.1419 66.0379 28.2287C66.0739 28.3155 66.1266 28.3943 66.1931 28.4607C66.2596 28.5271 66.3385 28.5798 66.4254 28.6156C66.5122 28.6515 66.6053 28.6699 66.6993 28.6697H81.2003C81.2943 28.6699 81.3874 28.6515 81.4742 28.6156C81.5611 28.5798 81.64 28.5271 81.7065 28.4607C81.773 28.3943 81.8257 28.3155 81.8617 28.2287C81.8977 28.1419 81.9162 28.0489 81.9162 27.9549C81.9162 27.8609 81.8977 27.7679 81.8617 27.6811C81.8257 27.5943 81.773 27.5154 81.7065 27.449C81.64 27.3826 81.5611 27.33 81.4742 27.2942C81.3874 27.2583 81.2943 27.2399 81.2003 27.24H66.6993Z"
        fill="#E6E6E6"
      />
      <path
        d="M53.5037 34.097C52.96 34.097 52.5175 34.4178 52.5175 34.8119C52.5175 35.206 52.96 35.5267 53.5037 35.5267H99.9988C100.543 35.5267 100.985 35.206 100.985 34.8119C100.985 34.4178 100.543 34.097 99.9988 34.097H53.5037Z"
        fill="#E6E6E6"
      />
      <path
        d="M53.5037 38.3861C52.96 38.3861 52.5175 38.7068 52.5175 39.1009C52.5175 39.4951 52.96 39.8158 53.5037 39.8158H99.9988C100.543 39.8158 100.985 39.4951 100.985 39.1009C100.985 38.7068 100.543 38.3861 99.9988 38.3861H53.5037Z"
        fill="#E6E6E6"
      />
      <path
        d="M94.9977 15.2557C99.2105 15.2557 102.626 11.8406 102.626 7.62785C102.626 3.4151 99.2105 0 94.9977 0C90.785 0 87.3699 3.4151 87.3699 7.62785C87.3699 11.8406 90.785 15.2557 94.9977 15.2557Z"
        fill="#005CFF"
      />
      <path
        d="M94.9976 20.0279L92.6125 15.8968L90.2275 11.7657H94.9976H99.7677L97.3827 15.8968L94.9976 20.0279Z"
        fill="#005CFF"
      />
      <path
        d="M95.0592 10.3171C96.5707 10.3171 97.796 9.09182 97.796 7.58035C97.796 6.06888 96.5707 4.84359 95.0592 4.84359C93.5477 4.84359 92.3224 6.06888 92.3224 7.58035C92.3224 9.09182 93.5477 10.3171 95.0592 10.3171Z"
        fill="white"
      />
      <path
        d="M123.777 48.1075C124.133 48.1075 124.421 47.7471 124.421 47.3026C124.421 46.858 124.133 46.4977 123.777 46.4977C123.422 46.4977 123.134 46.858 123.134 47.3026C123.134 47.7471 123.422 48.1075 123.777 48.1075Z"
        fill="#A0616A"
      />
    </svg>
  );
};

export default RefinanceSvg;
