interface DividerProps {
  className: string;
}

const VerticalDivider = ({ className }: DividerProps): JSX.Element => {
  return (
    <svg
      width="1"
      height="26"
      viewBox="0 0 1 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <line x1="0.5" y1="0.5" x2="0.499999" y2="25.5" stroke="#CECECE" />
    </svg>
  );
};
export default VerticalDivider;
