import { BrowserRouter, Route, Navigate } from 'react-router-dom';

import { SentryRoutes } from 'index';

import {
  MullbryESignConsentAgreementPdf,
  MullbryGlbaPrivacyPolicyPdf,
  MullbryInformationSharingDisclosurePdf,
} from './components/common/constants';
import DocumentWrapper from './components/common/DocumentWrapper';
import { AppRoutes } from './components/common/routes/routes.enums';
import ScrollToTop from './components/common/ScrollToTop';
import AboutPage from './components/landing/AboutPage';
import ContactPage from './components/landing/ContactPage';
import DocumentsPage from './components/landing/DocumentsPage';
import HowLoanProcessWorksPage from './components/landing/HowLoanProcessWorksPage';
import LandingPage from './components/landing/LandingPage';
import TermsOfUsePage from './components/landing/TermsOfUsePage';

const App = (): JSX.Element => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <SentryRoutes>
          <Route
            path={AppRoutes.HowItWorks}
            element={<HowLoanProcessWorksPage />}
          />
          <Route path={AppRoutes.About} element={<AboutPage />} />
          <Route path={AppRoutes.Contact} element={<ContactPage />} />
          <Route path={AppRoutes.Documents} element={<DocumentsPage />} />
          <Route
            path={AppRoutes.ESignConsentAgreement}
            element={
              <DocumentWrapper
                documentFileLocation={MullbryESignConsentAgreementPdf}
                documentTitle={'E-Sign Consent Agreement'}
                documentDownloadName={'Mullbry E-Sign Consent Agreement'}
              />
            }
          />
          <Route
            path={AppRoutes.GLBAPrivacyPolicy}
            element={
              <DocumentWrapper
                documentFileLocation={MullbryGlbaPrivacyPolicyPdf}
                documentTitle={'Privacy Policy'}
                documentDownloadName={'Mullbry GLBA Privacy Policy'}
              />
            }
          />
          <Route
            path={AppRoutes.InformationSharingDisclosure}
            element={
              <DocumentWrapper
                documentFileLocation={MullbryInformationSharingDisclosurePdf}
                documentTitle={'Information Sharing Disclosure and Consent'}
                documentDownloadName={
                  'Mullbry Information Sharing Disclosure and Consent'
                }
              />
            }
          />
          <Route path={AppRoutes.TermsOfUse} element={<TermsOfUsePage />} />
          <Route path={AppRoutes.Home} element={<LandingPage />} />
          <Route path="*" element={<Navigate replace to={AppRoutes.Home} />} />
        </SentryRoutes>
      </BrowserRouter>
    </>
  );
};

export default App;
