export enum HomeType {
  New = 'New',
  Used = 'Used',
  ReallyUsed = 'ReallyUsed',
}

export enum CreditScoreCategory {
  Under600 = '>600',
  SixHundredToFiveFifty = '600-650',
  SixFiftyOneToSevenHundred = '651-700',
  SevenHundredToSevenFifty = '701-750',
  OverSevenFifty = '750+',
}

export interface PaymentEstimatorInputs {
  homeType?: HomeType;
  creditScoreBand?: CreditScoreCategory;
  haveCoapplicant?: boolean;
  homePrice?: number;
  downPaymentPercent?: number;
}

export function creditScoreBound(category: CreditScoreCategory) {
  if (category === CreditScoreCategory.Under600) {
    return 500;
  } else if (category === CreditScoreCategory.SixHundredToFiveFifty) {
    return 600;
  } else if (category === CreditScoreCategory.SixFiftyOneToSevenHundred) {
    return 650;
  } else if (category === CreditScoreCategory.SevenHundredToSevenFifty) {
    return 700;
  } else {
    return 750;
  }
}
