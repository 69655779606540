import React from 'react';

import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';

interface HeaderFooterWrapperProps {
  children: React.ReactNode;
}

const HeaderFooterWrapper = ({
  children,
}: React.PropsWithChildren<HeaderFooterWrapperProps>): JSX.Element => {
  return (
    <div>
      <SiteHeader />
      {children}
      <SiteFooter />
    </div>
  );
};

export default HeaderFooterWrapper;
