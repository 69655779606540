import React from 'react';

import {
  HelpAddress,
  HelpEmail,
  HelpPhoneNumber,
} from 'components/common/constants';
import HeaderFooterWrapper from 'components/common/HeaderFooterWrapper';

const TermsOfUsePage = (): JSX.Element => {
  return (
    <HeaderFooterWrapper>
      <div className=" py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Terms of Use
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                The words “we,” “us,” “our,” “Mullbry” and “the Company” refer
                to each entity (individually) and all entities (collectively) in
                this list: Mullbry, INC, Mullbry LLC, Mullbry Originations LLC;
                and other subsidiaries and affiliates of Mullbry, INC.
                identified as the party providing products or services to you.
                “You” refers to any user and all users of our services when they
                visit, access, or use a website or mobile application that we
                own, administer, or sponsor in connection with our services (a
                “Site”). These Terms of Use govern your use of this Site. Please
                read these terms and conditions carefully before using this
                Site. By accessing or using this Site, you agree to be bound by
                these Terms of Use. If you do not agree to all of the terms,
                please immediately discontinue accessing this Site. If any
                provision contained in these Terms of Use conflicts with a
                provision contained in another agreement that you enter into
                with Mullbry regarding the products and services offered on this
                Site, the provision of the other agreement shall govern with
                respect to that specific aspect of the Site, product, or
                services.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Site Content and Use
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                This Site is owned and operated by Mullbry and may provide
                general information about our products and services. Your
                eligibility for particular products and services is subject to
                our final determination, restrictions, and acceptance. We may
                discontinue or make changes to the information, products,
                licenses, or services described on this Site at any time. Any
                dated information is published as of its publication date only.
                We do not undertake any obligation or responsibility to update
                or amend any such information. We reserve the right to terminate
                any or all offerings without prior notice. By offering
                information, products, or services via this Site, no
                solicitation is made by us to any person to use such
                information, products, or services in jurisdictions where the
                provision of information, products, or services is prohibited by
                law.
              </p>
              <p>
                This Site may contain links to third-party sites, such as social
                media sites, which we do not own or control, but which are being
                provided for your convenience. If you select a link to visit or
                use a third-party site, you do so at your own risk and subject
                to the terms and conditions established by the operator of that
                website. The fact that we provide a link to a site does not
                necessarily mean we endorse, authorize, or sponsor that
                third-party site, or that we are affiliated with the owners or
                sponsors of the third-party site. We reserve the right to
                terminate a link to a third-party site at any time.
              </p>
              <p>
                Although we try to provide accurate and timely information on
                this Site, there may be inadvertent, technical, or factual
                inaccuracies and typographical errors. For these reasons, we
                cannot warrant the accuracy, completeness, or timeliness of the
                information, text, graphics, links, or other items on this Site.
              </p>
              <p>
                This Site’s content, including but not limited to all music,
                images, icons, text, software, logos, expressions, ideas, and
                other information (collectively, the “Platform Content”) is
                copyrighted and protected by U.S. and worldwide copyright laws
                and treaty provisions. In addition, this Site’s content is
                protected by patent and trademark laws, the laws of privacy and
                publicity, and the various communication regulations and
                statutes. You may not copy, reproduce, distribute, republish,
                display, post, transmit, or sell Platform Content in any form or
                by any means without Mullbry’s prior express written consent.
                You acknowledge that all Platform Content is and shall remain
                the sole property of Mullbry. You may access the Site and use
                our related services only for their intended purpose. Any use of
                the Site or Mullbry’s services that is not expressly authorized
                by these Terms of Use is strictly prohibited.
              </p>
              <p>
                You agree to abide by all applicable laws and regulations in
                your use of the Site and the products and services available
                through the Site. You accept sole responsibility for all your
                activities using the Site. You may not use the Site in any
                manner that:
              </p>
              <ul>
                <li>
                  Uses technology or other means not authorized by us to access
                  the Site or our systems;
                </li>
                <li>
                  Uses or launches any manual or automated device or system,
                  including “robots,” “spiders,” or “offline readers,” to
                  <ul>
                    <li>
                      (a) retrieve, index, “scrape,” “data mine,” access or
                      otherwise gather any website information or our systems,{' '}
                    </li>
                    <li>
                      (b) reproduce or circumvent the navigational structure or
                      presentation of the website, or{' '}
                    </li>
                    <li>
                      (c) otherwise harvest or collect information about users
                      of the Site;
                    </li>
                  </ul>
                </li>
                <li>
                  Uses the Site or our products or services in any manner that
                  could damage, disable, or overburden or impair the Site or
                  interfere with any other party’s use of the Site, including
                  their ability to engage in real time activities through the
                  Site;
                </li>
                <li>
                  Reverse engineers, decompiles or disassembles any portion of
                  the Site, except where such restriction is expressly permitted
                  by applicable law;
                </li>
                <li>
                  Attempts to introduce viruses or any other computer code,
                  files, or programs that interrupts, destroys, or limits the
                  functionality of any software, hardware, or telecommunications
                  equipment;
                </li>
                <li>
                  Attempts to gain unauthorized access to our computer network
                  or user accounts;
                </li>
                <li>
                  Transmits to or from this Site any unlawful, threatening,
                  libelous, defamatory, obscene, scandalous, inflammatory,
                  pornographic, immoral, or profane material, or any other
                  content that could give rise to any civil or criminal
                  liability under the law;
                </li>
                <li>
                  Harasses, abuses, stalks, threatens, defames, or otherwise
                  infringes or violates the rights of us or any other party
                  (including rights of publicity or other proprietary rights);
                </li>
                <li>
                  Impersonates any person or entity, or falsely states or
                  otherwise misrepresents yourself, your age, or your
                  affiliation with any person or entity;
                </li>
                <li>Is unlawful, fraudulent, or deceptive;</li>
                <li>
                  Attempts to damage, disable, overburden, or impair our servers
                  or networks;
                </li>
                <li>
                  Reproduces, modifies, adapts, translates, creates derivative
                  works of, sells, rents, leases, loans, timeshares, distributes
                  or otherwise exploits any portion of (or any use of) the Site,
                  except as expressly authorized by these Terms of Use, without
                  our prior express written consent;
                </li>
                <li>Fails to comply with applicable third-party terms; or</li>
                <li>Otherwise violates these Term of Use.</li>
              </ul>
              <p>
                Any commercial use of this Site or its content is prohibited.
                You may print a copy of the information contained on this Site
                only for your personal use. This right may be revoked at any
                time.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Accessing the Site
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                You agree and acknowledge that you have the sole responsibility
                and liability for your use of this Site and for providing or
                obtaining, and for maintaining, all of the hardware, software,
                electrical power, telecommunications, Internet services, and
                other products or services necessary or desirable for you to
                access and use this Site.
              </p>
              <p>
                From time to time, due to the performance of maintenance,
                malfunctions or failures of software, equipment, or
                telecommunications devices, unusual transaction volume, or
                similar reasons, the Site may not be available for your use. We
                minimize the periods of time during which the Site is
                unavailable. You agree that we shall not be responsible for any
                loss, damages, costs, or expenses which you may suffer or incur,
                directly or indirectly, as a result of the unavailability of the
                Site, regardless of whether it could be shown that we could have
                prevented or reduced the duration of such unavailability by
                taking any action within our reasonable control. We shall also
                not be responsible for any loss, damages, costs, or expenses
                which you may suffer or incur, directly or indirectly, as a
                result of your inability to access the Site caused directly or
                indirectly, in whole or in part, by your computer or mobile
                device, your inability to establish a connection to the
                Internet, your Internet Service Provider (“ISP”), or capacity or
                other limitations or constraints of the Internet.
              </p>
              <p>
                We may at any time, with or without cause, and without prior
                notice to you, deny you access to the Site. We may terminate
                these Terms of Use and your access to the Site, in whole or in
                part, at any time and without prior notice to you. In the event
                we terminate these Terms of Use, any applications you have
                submitted will continue to be evaluated, and any existing
                transactions you have entered into with us shall remain in
                effect.
              </p>
              <p>
                The Site is not intended for use by persons in any jurisdiction
                or country where such use would be contrary to applicable laws
                or regulations. We may restrict your access to the Site during
                times you are in a country for which use of the Site would be
                prohibited. You are responsible for compliance with all local
                laws.
              </p>
              <p>
                The Site may be unavailable or may not work properly due to
                factors outside our control, including, but not limited to:
                software or hardware failure, severe weather, earthquakes, wars,
                insurrection, riots, civil commotion, acts of God, accident,
                damage, natural disasters, interruption of power, governmental
                or regulatory restrictions, or other causes beyond Mullbry’s
                control. To protect the security of your information, we may
                require you to authenticate your identity (i.e., prove that you
                are who you say you are) to conduct certain transactions on this
                Site, which may include registering for an account on the Site
                to access certain information. It is a good idea to protect your
                security by always closing your web browser after leaving the
                Site. If you believe that information that you have submitted
                through the Site has been used without your permission, you must
                tell us immediately.
              </p>
              <p>
                Telephoning us is the best way of keeping your possible losses
                to a minimum. Call us at {HelpPhoneNumber} to report
                unauthorized access. If you fail to notify us, you may be liable
                for all unauthorized activity on your account. You can also
                contact us at {HelpPhoneNumber} regarding technical issues with
                this Site.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Mobile Application
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                If you have elected to download our mobile application, we grant
                you a limited, non-transferable, revocable license to use the
                object code of the software within the mobile application on any
                mobile device that you own or control that such mobile
                application is authorized to be operated on (as determined by us
                in our sole discretion) and that is permitted by this section.
                The mobile application is licensed, not sold, to you for use
                only under the terms of these Terms of Use. We reserve all
                rights, title, and interest not expressly granted to you.
                Nothing in these Terms of Use allows you to use the mobile
                application on a device that you do not own or are not
                authorized to control.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                SMS Consent
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                Providing your telephone contact information to us means you
                have consented in writing to receive SMS communications (text
                messages) from us. When consenting to messaging notifications,
                you authorize us and our assigns, successors or servicing agents
                to send SMS Notifications (as defined below) to any phone number
                provided to us or to our assigns, successors or service agents
                in connection with your account, application, loan, and closing.
                As used in this text messaging disclosure, “SMS Account
                Notifications” means any SMS (text message) communications from
                us to you pertaining to your account or loan transaction sent to
                the phone number provided in connection with this transaction,
                including but not limited to application processing status,
                account information, loan information, information requests,
                document requests, due dates, delinquent accounts, closings and
                program updates
              </p>
              <p>
                How to Unsubscribe: You may withdraw your consent to receive SMS
                Account Notifications by replying with “STOP, END, CANCEL.
                UNSUBSCRIBE, or QUIT” or by calling us at {HelpPhoneNumber} at
                any time. We may treat your provision of an invalid mobile phone
                number, or the subsequent malfunction of a previously valid
                mobile phone number, as a withdrawal of your consent to receive
                SMS Account Notifications. Any withdrawal of your consent to use
                SMS Account Notifications will be effective only after we have a
                reasonable period of time to process your withdrawal.
              </p>
              <p>
                To request additional information, contact us by telephone at{' '}
                {HelpPhoneNumber}. In order to access, view, and retain SMS
                Account Notifications that we make available to you, you must
                have:
              </p>
              <ul>
                <li>a SMS-capable mobile phone,</li>
                <li>
                  an active mobile phone account with a communication service
                  provider; and
                </li>
                <li>sufficient storage capacity on your mobile phone.</li>
              </ul>
              <p>
                All SMS Account Notifications in electronic format from us to
                you will be considered “in writing.”
              </p>
              <p>
                There is no service fee for SMS Account Notifications but you
                are responsible for any and all charges, including but not
                limited to fees associated with text messaging, imposed by your
                communications service provider. Please consult your mobile
                service carrier’s pricing plan to determine the charges for
                sending and receiving text messages. These charges will appear
                on your phone bill. Message frequency depends on account status
                or settings. We may modify or terminate our text messaging
                services from time to time, for any reason, and without notice,
                including the right to terminate text messaging with or without
                notice, without liability to you.
              </p>
              <p>
                MARKETING CALLS AND TEXTS: We will not use autodialed or
                prerecorded calls or texts to contact you for marketing purposes
                unless you provide us with prior express written consent. If you
                have provided consent to receive marketing communications, you
                agree that Mullbry’s employees, agents, and representatives may
                use autodialed or pre-recorded phone calls and SMS text messages
                to contact your mobile phone at the number you provide for the
                purpose of describing goods and services that may be of interest
                to you, whether offered by Mullbry, affiliates, or third
                parties. If provided, your consent will be effective even if the
                number you have provided is registered on any state or federal
                Do-Not-Call (DNC) list. This consent for telemarketing calls
                shall remain in effect until you revoke it. Your consent to
                receive telephone communications is not a condition of obtaining
                any product or service and may be revoked at any time by email
                at {HelpEmail}, by writing to us at Mullbry, Inc. {HelpAddress},
                or by calling us at {HelpPhoneNumber}.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Your Information
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                During the process of your loan application, you may submit
                certain information to us. We do not claim ownership of the
                materials you provide (including feedback and suggestions),
                post, upload, input, or submit to us (collectively “Your
                Information”). However, by posting, uploading, inputting,
                providing, or submitting Your Information, you are granting us,
                our affiliated companies, and any vendors necessary to the
                course of your transaction permission to use Your Information to
                fulfill your request for services. That permission includes
                allowing us to copy, distribute, transmit, publicly display (we
                will never publicly display your non-public personal
                information), reproduce, edit, translate, and reformat Your
                Information. Please note that specific terms and conditions
                provided on any Site page describing a particular feature or
                offer may supersede this provision.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Privacy
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                Your privacy is very important to us. Please carefully read
                Mullbry’s Online Privacy Policy and our U.S. Consumer Financial
                Privacy Notice, which detail how we treat your personal
                information.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Copyright Complaints
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                If you believe that any material on the website infringes upon
                any copyright that you own or control, you may send a written
                notification to us via email at {HelpEmail}. In your
                notification, please:
              </p>
              <ul>
                <li>
                  Confirm that you are the owner, or authorized to act on behalf
                  of the owner, of the copyrighted work that has been infringed;
                </li>
                <li>
                  Identify the copyrighted work or works that you claim have
                  been infringed;
                </li>
                <li>
                  Identify the material that you claim is infringing or is the
                  subject of infringing activity and that is to be removed
                  (please include information reasonably sufficient to permit us
                  to locate the material);
                </li>
                <li>
                  Provide your contact details, including an email address; and
                </li>
                <li>
                  Provide a statement that the information you have provided is
                  accurate and that you have a good-faith belief that use of the
                  material in the manner complained of is not authorized by the
                  copyright owner, its agent or the law.
                </li>
              </ul>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Consent to Employment Verification
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                By providing us with your employment information you are
                providing written consent permitting us to contact third parties
                as necessary to verify your income and employment.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Limitations of Liability
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                UNDER NO CIRCUMSTANCES SHALL MULLBRY OR ITS DIRECTORS, OFFICERS,
                EMPLOYEES, OR AGENTS BE LIABLE FOR ANY DIRECT OR INDIRECT,
                SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES THAT MAY ARISE IN
                CONNECTION WITH THIS SITE, OR FROM YOUR USE OF, OR INABILITY TO
                USE, THIS SITE OR ANY INFORMATION PROVIDED ON THIS SITE; OR IN
                CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
                INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION,
                COMPUTER VIRUS OR LINE OR SYSTEM FAILURE; OR DISCLOSURE OF
                INFORMATION WHEN REPLYING TO YOU BY E-MAIL OR OTHER ELECTRONIC
                MEANS OR RECEIVING E-MAILS FROM YOU; EVEN IF WE ARE ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES, LOSSES, OR EXPENSES. Some
                jurisdictions do not allow the exclusion or limitation of
                liability for consequential or incidental damages. In such
                jurisdictions, our liability is limited to the greatest extent
                permitted by law.
              </p>
              <p>
                YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THIS WEBSITE
                CONTENT IS PROVIDED “AS IS” AND “AS AVAILABLE” AND WITHOUT
                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                ADDITIONALLY, MULLBRY MAKES NO CLAIM OR GUARANTEE AS TO THE
                ACCURACY, COMPLETENESS, SECURITY, RELIABILITY, QUALITY, OR
                AVAILABILITY OF ANY INFORMATION OR CONTENT PROVIDED ON THE SITE.
              </p>
              <p>
                WE ASSUME NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY
                VIRUSES THAT MAY INFECT OR DAMAGE YOUR COMPUTER EQUIPMENT OR
                OTHER PROPERTY AS A RESULT OF YOUR ACCESS TO, USE OF, OR
                BROWSING OF THIS SITE, THE WEB, OR YOUR DOWNLOADING OF ANY
                MATERIALS, DATA, TEXT, IMAGES, VIDEO, OR AUDIO FROM THIS SITE OR
                THE WEB.
              </p>
              <p>
                WE DO NOT WARRANT NOR REPRESENT THAT YOUR USE OF MATERIALS
                DISPLAYED ON THIS SITE WILL NOT INFRINGE RIGHTS OF THIRD PARTIES
                NOT OWNED OR AFFILIATED WITH US. You agree to indemnify and hold
                us harmless from and against any and all claims, losses,
                expenses, demands, or liabilities, including attorneys’ fees and
                costs, incurred by us in connection with any claim by a third
                party (including any intellectual property claim) arising out of
                your use of the Site in violation of these Terms of Use or in
                violation of any applicable law. You further agree that you will
                cooperate fully in the defense of any such claims. We reserve
                the right, at our own expense, to assume the exclusive defense
                and control of any matter otherwise subject to indemnification
                by you, and you shall not in any event settle any such claim or
                matter without our written consent.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Other Terms & Conditions
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                These Terms of Use will be governed by the laws of the United
                States and the State of Delaware without giving effect to any
                conflict of laws to the contrary.
              </p>
              <p>
                No failure, omission, or delay on our part to exercise any right
                under these Terms of Use will preclude any other further
                exercise of that right or other right under these Terms of Use.
              </p>
              <p>
                If any terms in these Terms of Use change due to applicable law
                or are declared invalid by order of a court, the remaining terms
                of these Terms of Use will not be affected, and these Terms of
                Use will be interpreted as if the invalid terms had not been
                placed in these Terms of Use. The headings in these Terms of Use
                are intended only to help organize these Terms of Use.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Changes in Contact Information
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                Please keep us informed of any changes in you contact
                information so that you can continue to receive timely
                electronic Communications from us. You may update your contact
                information by emailing {HelpEmail}.
              </p>
            </div>
          </div>
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Contacting Us
              </h1>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>
                If you have questions, comments, or complaints regarding these
                Terms of Use, the Site, or services email us at {HelpEmail}
              </p>
            </div>
          </div>
          <div className="md:bg-white md:p-6">
            <div className="prose prose-lg text-gray-900 lg:max-w-none">
              <p>Last Updated: December 13th, 2022</p>
            </div>
          </div>
        </div>
      </div>
    </HeaderFooterWrapper>
  );
};

export default TermsOfUsePage;
