import React, { useState } from 'react';

import { CircularProgress, Slider } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import { PaymentEstInput } from 'components/common/api/types/paymentEstInput';
import { PaymentEstOutput } from 'components/common/api/types/paymentEstOutput';
import { API_BASE_URL_BACKEND } from 'components/common/constants';
import { PortalRoutes } from 'components/common/routes/portal.enums';
import RefinanceSvg from 'components/common/svg/RefinanceSvg';
import {
  creditScoreBound,
  CreditScoreCategory,
  HomeType,
  PaymentEstimatorInputs,
} from 'components/paymentEstimator/PaymentEstimatorTypes';

interface PaymentEstimatorProps {
  setPaymentEstimatorVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentEstimator = ({
  setPaymentEstimatorVisible,
}: PaymentEstimatorProps): JSX.Element => {
  const [paymentEstimatorInput, setPaymentEstimatorInput] =
    useState<PaymentEstimatorInputs | null>({
      downPaymentPercent: 20,
      homePrice: 50000,
      homeType: HomeType.New,
    });
  const [downPaymentRawValue, setDownPaymentRawValue] = useState<number>(10000);
  const [calculatedRate, setCalculatedRate] = useState<number>();
  const [calculatedPayment, setCalculatedPayment] = useState<number>();
  const [calculatedTerm, setCalculatedTerm] = useState<number>();
  const [showCalculatedRate, setShowCalculatedRate] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const spread = 1.0;
  const customMarksDownPaymentPercent = [
    { label: '0%', value: 0 },
    { label: '100%', value: 100 },
  ];

  function showLoaderFunct() {
    setShowLoader(true);

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);

    return () => clearTimeout(timer);
  }

  const submitEnabled = (): boolean => {
    if (
      paymentEstimatorInput?.creditScoreBand &&
      paymentEstimatorInput?.homePrice &&
      paymentEstimatorInput?.downPaymentPercent &&
      paymentEstimatorInput?.homeType &&
      paymentEstimatorInput?.haveCoapplicant !== undefined
    ) {
      return true;
    }

    return false;
  };

  const calculateRate = async () => {
    if (
      paymentEstimatorInput?.creditScoreBand &&
      paymentEstimatorInput?.homePrice &&
      paymentEstimatorInput?.downPaymentPercent &&
      paymentEstimatorInput?.homeType &&
      paymentEstimatorInput?.haveCoapplicant !== undefined
    ) {
      try {
        const paymentEstInput: PaymentEstInput = {
          homeType: paymentEstimatorInput.homeType,
          homePrice: paymentEstimatorInput.homePrice,
          downPaymentPercent: paymentEstimatorInput.downPaymentPercent,
          haveCoapplicant: paymentEstimatorInput.haveCoapplicant,
          creditScoreLowerBound: creditScoreBound(
            paymentEstimatorInput.creditScoreBand,
          ),
        };

        const response = await axios.post(
          `${API_BASE_URL_BACKEND}/payment-estimator/estimate`,
          paymentEstInput,
        );

        const paymentEstimatorOutput: PaymentEstOutput = response.data;

        setCalculatedRate(paymentEstimatorOutput.interestRate);
        setCalculatedPayment(paymentEstimatorOutput.monthlyPayment);
        setCalculatedTerm(paymentEstimatorOutput.termYears);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const handlePaymentEstimatorSubmit = (): void => {
    showLoaderFunct();
    calculateRate();
    setShowCalculatedRate(true);
  };

  const enabledButtonClass =
    'w-full inline-flex justify-center rounded-md border border-transparent ' +
    'shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none ' +
    'focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm';

  const disabledSubmitButton =
    'w-full inline-flex justify-center rounded-md border border-transparent ' +
    'shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-white hover:bg-gray-700 focus:outline-none ' +
    'focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm';

  const updateDownPayment = () => {
    if (
      paymentEstimatorInput?.homePrice &&
      paymentEstimatorInput?.downPaymentPercent
    ) {
      setDownPaymentRawValue(
        paymentEstimatorInput?.homePrice *
          (paymentEstimatorInput?.downPaymentPercent / 100),
      );
    }
  };

  const handleHomePriceChange = (_event: any, newValue: any) => {
    setPaymentEstimatorInput({
      ...paymentEstimatorInput,
      homePrice: newValue,
    });
    updateDownPayment();
  };

  const handleDownPaymentChange = (_event: any, newValue: any) => {
    setPaymentEstimatorInput({
      ...paymentEstimatorInput,
      downPaymentPercent: newValue,
    });
    updateDownPayment();
  };

  const circProgressCode = (
    <div className="flex justify-center mb-8">
      <CircularProgress size={100} />
    </div>
  );

  const showRateCode = (
    <div className="pt-2 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="mt-2 text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-900">
          We're always working to get the best deal for our clients.
        </h2>
      </div>

      <div className="pt-10">
        <dl className="lg:grid-cols-2 lg:gap-x-8 gap-y-10 grid grid-cols-2 gap-x-6">
          <RefinanceSvg />
          <div className="ml-4 mt-2 text-center ">
            <p className="mt-4 max-w-2xl text-xl text-gray-900 lg:mx-auto">
              We estimate that you could qualify for a rate near
            </p>
            <p className="mt-2 text-center max-w-2xl text-xl text-gray-900 font-extrabold lg:mx-auto">
              {(calculatedRate! - spread).toLocaleString()}% -{' '}
              {(calculatedRate! + spread).toLocaleString()}%
            </p>
          </div>
        </dl>
        <div className="ml-4 mt-2 text-center">
          <p className="mt-4 max-w-2xl text-xl text-gray-900 lg:mx-auto">
            Over a{' '}
            <div className="font-extrabold">{calculatedTerm} year loan, </div>{' '}
            that works out to a monthly payment of
          </p>
          <p className="mt-2 text-center max-w-2xl text-xl text-gray-900 font-extrabold lg:mx-auto">
            ${calculatedPayment?.toLocaleString()}
          </p>
        </div>
        <div className="text-center pb-10">
          <div className="max-w-7xl my-6 mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="mt-2 text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-900">
              Love this rate? Apply now, or try out some different values
            </h2>
          </div>
          <a
            type="button"
            className="whitespace-nowrap inline-flex items-center justify-center px-6 py-6 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
            href={PortalRoutes.ApplyNowPage}
          >
            <span>Click Here to Apply</span>
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900"
                id="modal-headline"
              >
                Let's see what your potential monthly payment could look like
              </h3>
              <div className="mt-2">
                <div className="my-8">
                  <p className="mb-2 font-semibold">What type of home is it?</p>
                  <div className="flex space-x-4">
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          homeType: HomeType.New,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.homeType === HomeType.New
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      New
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          homeType: HomeType.Used,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.homeType === HomeType.Used
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      Used
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          homeType: HomeType.ReallyUsed,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.homeType === HomeType.ReallyUsed
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      Really Used
                    </button>
                  </div>
                </div>

                <div className="my-8">
                  <p className="mb-2 font-semibold">
                    What's your FICO Credit Score?
                  </p>
                  <div className="flex space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          creditScoreBand: CreditScoreCategory.Under600,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.creditScoreBand ===
                        CreditScoreCategory.Under600
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      600 & Below
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          creditScoreBand:
                            CreditScoreCategory.SixHundredToFiveFifty,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.creditScoreBand ===
                        CreditScoreCategory.SixHundredToFiveFifty
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      601-650
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          creditScoreBand:
                            CreditScoreCategory.SixFiftyOneToSevenHundred,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.creditScoreBand ===
                        CreditScoreCategory.SixFiftyOneToSevenHundred
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      651-700
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          creditScoreBand:
                            CreditScoreCategory.SevenHundredToSevenFifty,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.creditScoreBand ===
                        CreditScoreCategory.SevenHundredToSevenFifty
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      701-750
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          creditScoreBand: CreditScoreCategory.OverSevenFifty,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.creditScoreBand ===
                        CreditScoreCategory.OverSevenFifty
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      750 & Up
                    </button>
                  </div>
                </div>
                <div className="my-8">
                  <p className="mb-2 font-semibold text-center">
                    How will you be applying?
                  </p>
                  <div className="flex space-x-4">
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          haveCoapplicant: false,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.haveCoapplicant === false
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      By Myself
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentEstimatorInput({
                          ...paymentEstimatorInput,
                          haveCoapplicant: true,
                        });
                      }}
                      className={`${
                        paymentEstimatorInput?.haveCoapplicant === true
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      With Co-applicant
                    </button>
                  </div>
                </div>
                <div className="my-8  mx-2">
                  <p className="mb-2 font-semibold">
                    What's the price of the home?
                  </p>
                  <p className="mb-2 font-semibold">
                    ${paymentEstimatorInput?.homePrice?.toLocaleString()}
                  </p>
                  <Slider
                    aria-label="Always visible"
                    min={0}
                    max={100000}
                    step={500}
                    value={paymentEstimatorInput?.homePrice}
                    onChange={handleHomePriceChange}
                  />
                </div>

                <div className="my-8 mx-2">
                  <p className="mb-2 font-semibold">
                    What size downpayment would you like to make?
                  </p>
                  <p className="mb-2 font-semibold">
                    {paymentEstimatorInput?.downPaymentPercent?.toLocaleString()}
                    %, or ${downPaymentRawValue?.toLocaleString()}
                  </p>
                  <Slider
                    aria-label="Custom marks"
                    min={0}
                    max={100}
                    step={0.5}
                    value={paymentEstimatorInput?.downPaymentPercent}
                    onChange={handleDownPaymentChange}
                    marks={customMarksDownPaymentPercent}
                  />
                </div>
              </div>
            </div>
          </div>

          {showCalculatedRate && showLoader && circProgressCode}
          {showCalculatedRate && !showLoader && showRateCode}
          <div className="py-4">
            <button
              type="button"
              onClick={handlePaymentEstimatorSubmit}
              disabled={!submitEnabled()}
              className={
                submitEnabled() ? enabledButtonClass : disabledSubmitButton
              }
            >
              Calculate my Rate
            </button>
            <button
              type="button"
              onClick={() => {
                setPaymentEstimatorVisible(false);
              }}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentEstimator;
