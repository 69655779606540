import { Link } from 'react-router-dom';

import HeaderFooterWrapper from 'components/common/HeaderFooterWrapper';
import { AppRoutes } from 'components/common/routes/routes.enums';

const DocumentsPage = (): JSX.Element => {
  return (
    <HeaderFooterWrapper>
      <div className="py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Documents
              </h1>
            </div>
          </div>
          <div className="">
            <div className="md:bg-white md:p-6">
              <div className="prose prose-lg text-gray-900 lg:max-w-none">
                <ul className="pl-8">
                  <li>
                    <Link
                      type="button"
                      className="font-semibold text-gray-500 hover:text-gray-900"
                      to={AppRoutes.TermsOfUse}
                    >
                      <span>Terms of Use</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      type="button"
                      className="font-semibold text-gray-500 hover:text-gray-900"
                      to={AppRoutes.GLBAPrivacyPolicy}
                    >
                      <span>Privacy Policy</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      type="button"
                      className="font-semibold text-gray-500 hover:text-gray-900"
                      to={AppRoutes.ESignConsentAgreement}
                    >
                      <span>E-Sign Consent Agreement</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      type="button"
                      className="font-semibold text-gray-500 hover:text-gray-900"
                      to={AppRoutes.InformationSharingDisclosure}
                    >
                      <span>Information Sharing Disclosure</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderFooterWrapper>
  );
};

export default DocumentsPage;
